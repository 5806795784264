// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCreXDXMnpzzCuT3KpprnYrxEzVbiaNzSY', // Your Firebase API key
    authDomain: 'munrosets.firebaseapp.com', // Your Firebase Auth domain ("*.firebaseapp.com")
    databaseURL: 'munrosets.firebaseio.com', // Your Firebase Database URL ("https://*.firebaseio.com")
    storageBucket: 'munrosets.appspot.com',  // Your Firebase Storage bucket ("*.appspot.com")
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

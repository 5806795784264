// Code (c) Bill Chadwick 2010, 2011 please dont use it without permission
// until I have selelected a suitable license.
// contact : bill.chadwick2@gmail.com
import { GridProjection, OgbPoint } from './GridProjection';

class Point {
    constructor(public x: number, public y: number) {}
};

export class WarpedOsOpenSpaceMapType {
    private osProjection = new GridProjection();
    private tiles: Element[] = new Array(); // collection of tiles on map for opacity control, pruned by releaseTile.
    copyright = '&copy; Crown copyright and database rights 2010 Ordnance Survey <a href=\"http://openspace.ordnancesurvey.co.uk/openspace/developeragreement.html#enduserlicense\" target=\"_blank\"> EULA</a>';
    opacity = 1.0;
    tileSize = new google.maps.Size(256, 256);
    maxZoom = 16; // limit consistent with most detailed OpenSpace mapping
    minZoom = 7; // limit to UK area, detailed maps only
    name = 'Ordnance Survey GB';
    alt = 'Ordnance Survey GB';
    idPrefix = 'WarpedOsOpenSpaceMapType';

    // Constructor. Pass your OpenSpace Key and your OpenSpace URL
    // and the Google map object that the OS map type is to be used with.
    // this code depends on GridProjection.js
    constructor(private osKey: string, private osUrl: string, private mapProjection: google.maps.Projection) {
    }

    getOpacity() { return this.opacity; }
    setOpacity(op) {
        this.opacity = op;
        for (const tile of this.tiles) {
            tile.setAttribute('opacity', `${this.opacity}`);
        }
    }

    getTile(coord, zoom, ownerDocument) {
        if ((zoom < this.minZoom) || (zoom > this.maxZoom)) {
            const d = document.createElement('div');
            d.innerHTML = 'No OS Data';
            d.style.color = 'yellow';
            return d;
        }

        const s = 1 << zoom; // google scale for zoom

        const svgNS = 'http://www.w3.org/2000/svg';
        const svgXlink = 'http://www.w3.org/1999/xlink';

        const svgRoot = ownerDocument.createElementNS(svgNS, 'svg');
        svgRoot.setAttribute('width', this.tileSize.width + 'px');
        svgRoot.setAttribute('height', this.tileSize.height + 'px');
        svgRoot.setAttribute('style', 'position:relative; top:' + 0 + 'px; left:' + 0 + 'px');
        svgRoot.setAttribute('image-rendering', 'optimizeQuality'); // optimiseSpeed
        const svgNode = ownerDocument.createElementNS(svgNS, 'g');

        // lat/lng bounds of google tile.
        const gTl = this.mapProjection.fromPointToLatLng(
            new google.maps.Point(coord.x * this.tileSize.width / s, coord.y * this.tileSize.height / s));
        const gTr = this.mapProjection.fromPointToLatLng(
            new google.maps.Point((coord.x + 1) * this.tileSize.width / s, coord.y * this.tileSize.height / s));
        const gBl = this.mapProjection.fromPointToLatLng(
            new google.maps.Point(coord.x * this.tileSize.width / s, (coord.y + 1) * this.tileSize.height / s));
        const gBr = this.mapProjection.fromPointToLatLng(
            new google.maps.Point((coord.x + 1) * this.tileSize.width / s, (coord.y + 1) * this.tileSize.height / s));

        // convert to OS and get containing bounds.
        const oTl = this.osProjection.getOgbPointFromLonLat(gTl);
        const oTr = this.osProjection.getOgbPointFromLonLat(gTr);
        const oBl = this.osProjection.getOgbPointFromLonLat(gBl);
        const oBr = this.osProjection.getOgbPointFromLonLat(gBr);

        // convert to pixels.
        const pTl = osgbToPixels(oTl, zoom);
        const pTr = osgbToPixels(oTr, zoom);
        const pBl = osgbToPixels(oBl, zoom);
        const pBr = osgbToPixels(oBr, zoom);
 
        // Get the pixel bounding box.
        const pLeft = Math.min(pTl.x, pBl.x);
        const pRight = Math.max(pTr.x, pBr.x);
        const pTop = Math.min(pTl.y, pTr.y)
        const pBottom = Math.max(pBl.y, pBr.y);

        const ts = 256;

        const topLeftTile = new Point(Math.floor(pLeft / ts), Math.floor(pTop / ts));
        const bottomRightTile = new Point(Math.ceil(pRight / ts), Math.ceil(pBottom / ts));

        // iterate OS tiles, enough to cover the google tile
        let svgX = 0;
        for (let x = topLeftTile.x; x < bottomRightTile.x; x++) {
            let svgY = 0;
            for (let y = topLeftTile.y; y < bottomRightTile.y; y++) {

                // work out the os URL
                const z = zoom - 7;
                const tx = x.toFixed(0);
                const ty = y.toFixed(0);
                const uC = `https://api.os.uk/maps/raster/v1/zxy/Leisure_27700/${z}/${tx}/${ty}.png?key=gLjQcJ7EYWtvZOcwDVGz9Uqn5vTeWznH`;

                const svgImg = ownerDocument.createElementNS(svgNS, 'image');
                svgImg.setAttributeNS(svgXlink, 'xlink:href', uC);
                svgImg.setAttribute('x', svgX + 'px');
                svgImg.setAttribute('y', svgY + 'px');
                svgImg.setAttribute('width', ts + 'px');
                svgImg.setAttribute('height', ts + 'px');
                svgImg.setAttribute('opacity', this.opacity);
                svgImg.setAttribute('style', 'border: 0px;');
                this.tiles.push(svgImg); // save for opacity control

                svgNode.appendChild(svgImg);
                svgY += ts;
            }
            svgX += ts;
        }

        // now compute the affine transformation matrix for the grid of OS tiles to the Google tile
        const gcps =
            [
             { source: { x: (pTl.x - topLeftTile.x * ts), y:  (pTl.y - topLeftTile.y * ts)}, dest: { x: 0, y: 0} },
             { source: { x: (pTr.x - topLeftTile.x * ts), y:  (pTr.y - topLeftTile.y * ts)}, dest: { x: this.tileSize.width, y: 0} },
             { source: { x: (pBl.x - topLeftTile.x * ts), y:  (pBl.y - topLeftTile.y * ts)}, dest: { x: 0, y: this.tileSize.height} },
             { source: { x: (pBr.x - topLeftTile.x * ts), y:  (pBr.y - topLeftTile.y * ts)}, dest: { x: this.tileSize.width, y: this.tileSize.height} }
            ];
        const mtx = affineFromGcps(gcps);
        if (mtx) {
            svgNode.setAttribute('transform',
                'matrix(' + mtx[1] + ',' + mtx[4] + ',' + mtx[2] + ',' +
                        mtx[5] + ',' + mtx[0] + ',' + mtx[3] + ')'
            );
        } else {
            console.log("No transform found for", gcps);
        }
        svgRoot.appendChild(svgNode);

        return svgRoot;
    }

    releaseTile(node) {
        for (const cn of node.childNodes) {
            for (const tile of cn.childNodes) {
                for (let i = 0; i < this.tiles.length; i++) {
                    if (this.tiles[i] === tile) {
                        this.tiles.splice(i, 1);
                        break;
                    }
                }
            }
        }
    }
}

// Returns the pixel coordinates for an ogb point *including fractional parts*.
// These can be turned into tiles later.
function osgbToPixels(osgb: OgbPoint, zoom: number): Point {

    const denominators = [
        3199999.999496063,
        1599999.9997480316,
        799999.9998740158,
        399999.9999370079,
        199999.99996850395,
        99999.99998425198,
        49999.99999212599,
        24999.999996062994,
        12499.999998031497,
        6249.9999990157485,
    ];

    const eastTileOffset = -238375.0;
    const northTileOffset = 1376256.0;

    const oz = zoom - 7;
    const res = (denominators[oz] * 0.28 /* mm */) / 1000;
    return new Point((osgb.east - eastTileOffset) / res, (northTileOffset - osgb.north) / res);
}


/// <summary>
/// Construct the least squares best fit affine transformation matrix from a set of ground control points
/// From Mapping Hacks #33
/// </summary>
/// <param name="gcps">Three or more ground control points having .source.x , .source.y and .dest.x and .dest.y</param>
/// <returns>Affine transformation matrix elements</returns>
// [0] is x offset
// [1] is x scale
// [2] is x rotation
// [3] is y offset
// [4] is y rotation
// [5] is y scale

function affineFromGcps(gcps) {

    let sum_x = 0.0, sum_y = 0.0, sum_xy = 0.0, sum_xx = 0.0, sum_yy = 0.0;
    let sum_Lon = 0.0, sum_Lonx = 0.0, sum_Lony = 0.0;
    let sum_Lat = 0.0, sum_Latx = 0.0, sum_Laty = 0.0;
    let divisor = 0.0;

    const affine = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0];

    if (gcps.length < 3) {
        return null;
    }

    for (let i = 0; i < gcps.length; ++i) {
        sum_x += gcps[i].source.x;
        sum_y += gcps[i].source.y;
        sum_xy += gcps[i].source.x * gcps[i].source.y;
        sum_xx += gcps[i].source.x * gcps[i].source.x;
        sum_yy += gcps[i].source.y * gcps[i].source.y;
        sum_Lon += gcps[i].dest.x;
        sum_Lonx += gcps[i].dest.x * gcps[i].source.x;
        sum_Lony += gcps[i].dest.x * gcps[i].source.y;
        sum_Lat += gcps[i].dest.y;
        sum_Latx += gcps[i].dest.y * gcps[i].source.x;
        sum_Laty += gcps[i].dest.y * gcps[i].source.y;
    }

    divisor = gcps.length * (sum_xx * sum_yy - sum_xy * sum_xy)
            + 2 * sum_x * sum_y * sum_xy - sum_y * sum_y * sum_xx
            - sum_x * sum_x * sum_yy;

    /* -------------------------------------------------------------------- */
    /*      If the divisor is zero, there is no valid solution.             */
    /* -------------------------------------------------------------------- */
    if (divisor == 0.0) {
        return null;
    }

    /* -------------------------------------------------------------------- */
    /*      Compute top/left origin.                                        */
    /* -------------------------------------------------------------------- */

    affine[0] = (sum_Lon * (sum_xx * sum_yy - sum_xy * sum_xy)
                               + sum_Lonx * (sum_y * sum_xy - sum_x * sum_yy)
                               + sum_Lony * (sum_x * sum_xy - sum_y * sum_xx))
            / divisor;

    affine[3] = (sum_Lat * (sum_xx * sum_yy - sum_xy * sum_xy)
                               + sum_Latx * (sum_y * sum_xy - sum_x * sum_yy)
                               + sum_Laty * (sum_x * sum_xy - sum_y * sum_xx))
            / divisor;

    /* -------------------------------------------------------------------- */
    /*      Compute X related coefficients.                                 */
    /* -------------------------------------------------------------------- */
    affine[1] = (sum_Lon * (sum_y * sum_xy - sum_x * sum_yy)
                               + sum_Lonx * (gcps.length * sum_yy - sum_y * sum_y)
                               + sum_Lony * (sum_x * sum_y - sum_xy * gcps.length))
            / divisor;

    affine[2] = (sum_Lon * (sum_x * sum_xy - sum_y * sum_xx)
                               + sum_Lonx * (sum_x * sum_y - gcps.length * sum_xy)
                               + sum_Lony * (gcps.length * sum_xx - sum_x * sum_x))
            / divisor;

    /* -------------------------------------------------------------------- */
    /*      Compute Y related coefficients.                                 */
    /* -------------------------------------------------------------------- */
    affine[4] = (sum_Lat * (sum_y * sum_xy - sum_x * sum_yy)
                               + sum_Latx * (gcps.length * sum_yy - sum_y * sum_y)
                               + sum_Laty * (sum_x * sum_y - sum_xy * gcps.length))
            / divisor;

    affine[5] = (sum_Lat * (sum_x * sum_xy - sum_y * sum_xx)
                               + sum_Latx * (sum_x * sum_y - gcps.length * sum_xy)
                               + sum_Laty * (gcps.length * sum_xx - sum_x * sum_x))
            / divisor;


    affine[0] += 0.5 * affine[1] + 0.5 * affine[2];
    affine[3] += 0.5 * affine[4] + 0.5 * affine[5];

    return affine;
}

import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';

import firebase from 'firebase/app';
import { User, UserService, List } from './api';
import { Observable, combineLatest } from 'rxjs';
import { shareReplay, filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUser: Observable<User>;

  constructor(private fireAuth: AngularFireAuth, private userService: UserService) {
    this.currentUser = combineLatest([this.fireAuth.authState, this.userService.entityMap$]).pipe(
      filter(([user, entityMap]) => user === null || !!entityMap[user.uid]),
      map(([user, entityMap]) => {
        if (!user) {
          return null;
        }
        return entityMap[user.uid];
      }),
      shareReplay(1));
  }

  public get userId(): Observable<string> {
    return this.fireAuth.authState.pipe(
      map(user => user?.uid)
    );
  }

  public get user(): Observable<User> {
    return this.currentUser;
  }

  async signIn() {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
     });
    const credential = await this.fireAuth.signInWithPopup(provider);
    console.log(credential);
    return credential;
  }

  async signOut() {
    await this.fireAuth.signOut();
  }

  getUserIdToken() {
    return this.fireAuth.idToken;
  }

  canEdit(list: List, user: User): boolean {
    return user && (list.user_id && list.user_id === user.id) || (!list.user_id && user?.admin);
  }
}

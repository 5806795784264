import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullscreenComponent } from './fullscreen/fullscreen.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { ConnectionsComponent } from './connections/connections.component';
import { SocialComponent } from './social/social.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminComponent } from './admin/admin.component';


const routes: Routes = [
  {
    path: 'profile',
    component: FullscreenComponent,
    children: [{
      path: '',
      component: DashboardComponent,
    }, {
      path: 'analysis',
      component: AnalysisComponent,
    }, {
      path: 'analysis/:userId',
      component: AnalysisComponent,
    }, {
      path: 'connections',
      component: ConnectionsComponent,
    }, {
      path: 'social',
      component: SocialComponent,
    }, {
      // TODO: Add auth guard.
      path: 'admin',
      component: AdminComponent,
    }],

}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }

import { Component, OnInit, ElementRef } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'app-google-map-control',
  templateUrl: './google-map-control.component.html',
  styleUrls: ['./google-map-control.component.scss']
})
export class GoogleMapControlComponent implements OnInit {

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
  }

  addToGoogleMap(map: GoogleMap, position: google.maps.ControlPosition) {
    map.controls[position].push(this.el.nativeElement);
  }
}

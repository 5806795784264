<div [class.hidden]="readyState !== AppState.READY">
    <mat-sidenav-container fullscreen>
        <mat-sidenav role="navigation" mode="over" fixedInViewport="true">
            <app-app-navigation></app-app-navigation>
        </mat-sidenav>
        <mat-sidenav-content>
            <router-outlet></router-outlet>
        </mat-sidenav-content>W
    </mat-sidenav-container>
</div>
<!-- Initial spinner while we load user info/lists. -->
<div *ngIf="readyState === AppState.LOADING" id="initialload">
    <div id="icon-spinner">
        <img src="/assets/icons/mountains192.png" />
        <mat-spinner diameter="250"></mat-spinner>
    </div>
</div>
<!-- Failed loading for some reason. -->
<!-- TODO: Style this. -->
<div *ngIf="readyState === AppState.ERROR" id="error">
    <p>
        An error occurred while loading the app.
    </p>
    <p>
        Please <a href="">reload</a>.
    </p>
    <p>
        {{error}}
    </p>
</div>
import {getOpenTopoMap} from './opentopomap';
import {getSwissTopoLayer} from './ch';
import {MultiTypeMultiplexer} from './mapmultiplexer';
import {WarpedOsOpenSpaceMapType} from './warped_os';

const nationalMapTypes: [google.maps.Polygon, google.maps.MapType][] = [
    // Czechia. Credit CUZK.
    [new google.maps.Polygon({
        paths: [
                {lat: 51.173288, lng: 11.661182},
                {lat: 48.481084, lng: 11.661182},
                {lat: 48.481084, lng: 19.022022},
                {lat: 51.173288, lng: 19.022022},
                ]
        }),
    new google.maps.ImageMapType({
        maxZoom: 17,
        minZoom: 9,
        name: 'cztopo',
        tileSize: new google.maps.Size(256, 256),
        getTileUrl: (coord, zoom) =>
                    `http://ags.cuzk.cz/ArcGIS/rest/services/zmwm/MapServer/tile/${zoom}/${coord.y}/${coord.x}`,
        opacity: 1.0
    })],
    // Spain. © Instituto Geográfico Nacional de España
    [ new google.maps.Polygon({
        paths: [
                {lat: 44.286, lng: 5},
                {lat: 44.286, lng: -10.162},
                {lat: 35, lng: -10.162},
                {lat: 35, lng: 5},
                ]
        }),
    new google.maps.ImageMapType({
        maxZoom: 21,
        minZoom: 8,
        name: 'estopo',
        tileSize: new google.maps.Size(256, 256),
        getTileUrl: (coord, zoom) =>
            `http://www.ign.es/wmts/mapa-raster?request=GetTile&Layer=MTN&TileMatrixSet=GoogleMapsCompatible&Format=image/png&TileMatrix=${zoom}&TileCol=${coord.x}&TileRow=${coord.y}`,
    })],
    // New Zealand. Credit LINZ.
    [new google.maps.Polygon({
        paths: [
                {lat: -28.861658, lng: 163.944304},
                {lat: -28.861658, lng: -172.67},
                {lat: -53.2, lng: -172.67},
                {lat: -53.2, lng: 163.944304},
                ]
        })
        ,
    new google.maps.ImageMapType({
        maxZoom: 20,
        minZoom: 10,
        name: 'nztopo',
        tileSize: new google.maps.Size(256, 256),
        getTileUrl: (coord, zoom) => `http://tiles-a.data-cdn.linz.govt.nz/services;key=65e5d7d1035a4dfa83702c27c61594aa/tiles/v4/layer=50767/EPSG:3857/${zoom}/${coord.x}/${coord.y}.png`
    })]
];
export function getNationalTopoMap(projection: google.maps.Projection) {
    const baseMap = getOpenTopoMap();
    const topoMapMultiplexer = new MultiTypeMultiplexer(
        new google.maps.Size(256, 256),
        projection,
        baseMap
        );
    topoMapMultiplexer.addMapTypes(nationalMapTypes);

    topoMapMultiplexer.addMapType(
        new google.maps.Polygon({
        paths: [
                {lat: 54.213861, lng: -8.3056641},
                {lat: 53.6706802, lng: -4.7460937},
                {lat: 49.439557, lng: -6.1962891},
                {lat: 50.4295179, lng: 0.1757813},
                {lat: 51.0413939, lng: 1.4941406},
                {lat: 53.0147832, lng: 3.0322266},
                {lat: 62.0421377, lng: 1.0107422},
                {lat: 61.8768702, lng: -3.6035156},
                {lat: 59.2209341, lng: -7.9980469},
                {lat: 54.749991, lng: -9.5800781},
                {lat: 54.213861, lng: -8.3056641}
                ]
        }),
        new WarpedOsOpenSpaceMapType('2CAB4BA1BE523636E0530C6CA40A4412', 'http%3A%2F%2Fhills.brown.io', projection));

    topoMapMultiplexer.addMapType(
        new google.maps.Polygon({
        paths: [
                {lat: 47.8131545, lng: 5.1416016},
                {lat: 45.0269505, lng: 5.8447265},
                {lat: 45.170273, lng: 8.6220477},
                {lat: 45.8287993, lng: 11.4697266},
                {lat: 48.3124279, lng: 10.7226563},
                {lat: 47.8131545, lng: 5.1416016},
                ]
        }),
        getSwissTopoLayer('topo'));

    return topoMapMultiplexer;
}

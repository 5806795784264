/**
 * Summit Tracker API
 * Provides POIs and user configuration for Summit Tracker
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InlineResponse200 { 
    /**
     * The username for hill-bagging.co.uk.
     */
    dobih_username?: string | null;
    /**
     * The ID from walkhighlands.
     */
    walkhighlands_id?: string | null;
}


<ng-container *ngIf="listSummary$ | async as listSummary;">
<div id="header" class="st-primary">
  <ng-container *ngIf="(rankedList$ | async) as pois; else noPic">
    <img *ngIf="pois && pois.length && pois[0].picture; else noPic" id="picture" class="photo" [src]="getResizedPhotoUrl(pois[0].picture, 400)" />
  </ng-container>
  <ng-template #noPic>
      <mat-icon id="picture" class="icon">
          terrain
      </mat-icon>
  </ng-template>

  <div class="text">
    <div class="progress-medal">
      {{listSummary.baggedCount * 100 / listSummary.total | number:'1.0-0'}}%
    </div>
    <div>
      <h1>
        <span class="name">
          {{listSummary.list.name}}
          <a *ngIf="listSummary.list.link" [href]="listSummary.list.link" target="_blank">
            <mat-icon>launch</mat-icon>
          </a>
        </span>
      </h1>
      <p>
        Completed {{listSummary.baggedCount}} of {{listSummary.total}}
      </p>
    </div>
  </div>
  <button mat-fab color="primary" class="st-sideactionfab" (click)="toggleFav(listSummary.list)">
    <mat-icon>{{listSummary.list.fav ? 'favorite' : 'favorite_outline'}}</mat-icon>
  </button>
</div>
<p class="details-panel">
  {{listSummary.list.description}}
</p>
<mat-toolbar *ngIf="listSummary.isEditable">
  <button mat-icon-button (click)="edit(listSummary.list)">
    <mat-icon>
      edit
    </mat-icon>
  </button>
</mat-toolbar>
<google-chart *ngIf="baggingTime"
  type="LineChart" 
  [options]="{legend: 'none', series: { '0': {pointSize: 2}}, vAxis: { viewWindow: {min: 0, max: listSummary.total}}}"
  [data]="baggingTime.data"
  [columns]="baggingTime.columns"
  [dynamicResize]="true" style="width: 100%; height: 100px">
</google-chart>
<mat-nav-list>
  <mat-list-item class="poi-list-item" *ngFor="let poi of rankedList$ | async; let i = index">
      <a matLine [routerLink]="['/poi/', poi.id]" queryParamsHandling="merge">
          <h3 matLine>
              <span class="ranking">{{i+1}}.</span>
              <span class="name">
                {{poi.name}}
                <mat-icon *ngIf="listSummary.baggedIds.includes(poi.id)" class="check">
                  check_circle
                </mat-icon>
              </span>
              <span class="height">{{poi.height}}</span>
          </h3>
          <p matLine>
              <mat-icon [svgIcon]="poi.country ? getFlagIcon(poi.country.toLowerCase()) : ''"></mat-icon>
              {{poi.country}}&nbsp;
              <span *ngIf="poi.region"> - {{poi.region}}</span>
          </p>
      </a>
      <button mat-icon-button *ngIf="listSummary.isEditable && !listSummary.list.automatic" (click)="deleteFromList(listSummary.list, poi)">
        <mat-icon matListIcon>delete</mat-icon>
      </button>
  </mat-list-item>
</mat-nav-list>
</ng-container>

/**
 * Summit Tracker API
 * Provides POIs and user configuration for Summit Tracker
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Mode = 'hiking' | 'climbing' | 'mtb' | 'roadcycling' | 'gondola' | 'driving' | 'sota';

export const Mode = {
    Hiking: 'hiking' as Mode,
    Climbing: 'climbing' as Mode,
    Mtb: 'mtb' as Mode,
    Roadcycling: 'roadcycling' as Mode,
    Gondola: 'gondola' as Mode,
    Driving: 'driving' as Mode,
    Sota: 'sota' as Mode
};


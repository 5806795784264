import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DefaultService as Api, PoiService, Poi } from 'src/app/api';
import { PhotoService } from 'src/app/photo.service';
import { Observable, combineLatest } from 'rxjs';
import { map, take, filter, tap } from 'rxjs/operators';
import { CountryService } from 'src/app/country.service';
import { AuthService } from 'src/app/auth.service';
import { MapService } from '../map.service';

@Component({
  selector: 'app-multiple-pois',
  templateUrl: './multiple-pois.component.html',
  styleUrls: ['./multiple-pois.component.scss']
})
export class MultiplePoisComponent implements OnInit {
  pois$: Observable<Poi[]>;

  constructor(
    private api: Api,
    private authService: AuthService,
    private route: ActivatedRoute,
    private mapService: MapService,
    private poiService: PoiService,
    public countries: CountryService,
    public photos: PhotoService) { }

  user$ = this.authService.user;
  merging = false;

  ngOnInit(): void {
    const poiIds$ = this.route.paramMap.pipe(
      map(paramMap => paramMap.get('poiIds').split(',')),
    );
    poiIds$.subscribe((ids) => {
      for (const id of ids) {
        this.poiService.getByKey(id);
      }
    });
    this.pois$ = combineLatest([poiIds$, this.poiService.entityMap$]).pipe(
      filter(([poiIds, poiMap]) => poiIds.filter(id => !poiMap[id]).length === 0),
      map(([poiIds, poiMap]) => poiIds.map(id => poiMap[id]))
    );
    this.pois$.pipe(take(1)).subscribe(pois => this.mapService.setSelection(pois));
  }

  merge(target: Poi, others: Poi[]): void {
    this.merging = true;
    this.api.adminMergeIntoPoi(target.id, others.map(p => p.id)).subscribe(
      (poi) => {
        this.merging = false;
        this.poiService.updateOneInCache(poi);
        this.poiService.removeManyFromCache(others);
        this.mapService.refresh();
        this.mapService.setSelection([poi]);
      },
      // TODO: Add snackbar.
      (error) => {
        this.merging = false;
        console.log(error);
      },
    );
  }
}

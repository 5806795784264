import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DefaultService as ApiService, UserService, User } from 'src/app/api';
import { PhotoService, AvatarSize } from 'src/app/photo.service';
import { map, filter, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {
  @ViewChild('addUserDialog') addUserDialog: TemplateRef<any>;

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private photos: PhotoService,
  ) { }

  emailControl = new FormControl();
  people$ = this.userService.entities$.pipe(
    map(people => people.filter(person => !!person.follower || !!person.following)));

  ngOnInit(): void {
  }

  getAvatarUrl(url?: string) {
    return this.photos.getAvatarUrl(url, AvatarSize.Medium);
  }

  acceptFollow(user: User) {
    this.api.approveFollowForUser(user.id).subscribe(() => this.userService.getAll());
  }

  rejectFollow(user: User) {
    this.api.rejectFollowForUser(user.id).subscribe(() => this.userService.getAll());
  }

  followBack(user: User) {
    this.api.sendFollowRequestByEmail(user.email).subscribe(() => this.userService.getAll());
  }

  followByEmail() {
    this.dialog.open(this.addUserDialog).afterClosed().pipe(
      filter(email => !!email),
      switchMap(email => this.api.sendFollowRequestByEmail(email))
    ).subscribe(
      (success) => this.userService.getAll(),
      (error) => this.snackBar.open((error as HttpErrorResponse).statusText, 'DISMISS', {duration: 3000})
    );
  }

  isPendingFollower(user: User) {
    return user.follower === 'pending';
  }

  isPendingFollow(user: User) {
    return user.following === 'pending';
  }

  isFollowed(user: User) {
    return user.following === 'approved';
  }

  isFollower(user: User) {
    return user.follower === 'approved';
  }

  classifyPerson(user: User) {
    return 'sync_alt';
  }
}

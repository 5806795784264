<div id="expansion" class="st-expansion-panel">
    <div id="expansionHeader" matAutocompleteOrigin #origin="matAutocompleteOrigin">
        <button mat-icon-button (click)="toggleSidenav()">
            <mat-icon>
                menu
            </mat-icon>
        </button>
        <!-- TODO: change colour based on panel status. -->
        <button mat-icon-button (click)="togglePanel()">
            <mat-icon>place</mat-icon>
        </button>
        <button [disabled]="!locateAllowed" mat-icon-button (click)="locate()">
          <mat-icon>{{ locateError? 'gps_off' : locateAllowed ? 'gps_fixed' : 'gps_not_fixed'}}</mat-icon>
        </button>
        <input type="text" [formControl]="searchControl" [matAutocomplete]="auto" [matAutocompleteConnectedTo]="origin" placeholder="Search for mountains, huts & others features" />
        <mat-spinner *ngIf="loading" id="spinner" diameter="20">
        </mat-spinner>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="display" (optionSelected)="selectPoi($event.option.value)">
            <mat-option *ngFor="let poi of searchResults$ | async" [value]="poi">
              <mat-icon *ngIf="poi.country" [svgIcon]="'flags:' + getFlagIcon(poi.country || '')"></mat-icon>
              {{poi.name}} <span *ngIf="poi.region"> - {{poi.region}}</span>
            </mat-option>
        </mat-autocomplete>
        <button mat-icon-button (click)="close()" *ngIf="closeAllowed">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div id="expansionBody" [@panelExpansion]="expanded ? 'expanded' : 'collapsed'">
      <!-- Users -->
      <mat-form-field appearance="standard" *ngIf="(user$ | async) as user" style="width: 100%">
          <mat-chip-list #people>
              <mat-chip *ngFor="let selectedUser of selectedUsers$ | async" (removed)="userRemoved(selectedUser)">
                <img matChipAvatar [src]="getAvatarUrl(selectedUser.photo_url)" />
                {{selectedUser.name}}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input #peopleInput matInput type="text" [formControl]="peopleControl" [matChipInputFor]="people" [matAutocomplete]="peopleComplete" placeholder="Add people by name" />
              <mat-icon matSuffix>people</mat-icon>
          </mat-chip-list>
          <mat-autocomplete #peopleComplete="matAutocomplete" (optionSelected)="userSelected($event.option.value)">
              <mat-option *ngFor="let user of filteredUsers$ | async" [value]="user">{{user.name}}</mat-option>
          </mat-autocomplete>
      </mat-form-field>
      <!-- Types -->
      <mat-button-toggle-group *ngIf="(selectedTypes$ | async) as selectedTypes" name="poi_type" aria-label="Type" multiple (change)="typesUpdated($event)">
        <mat-button-toggle *ngFor="let poiType of PoiType | keyvalue"
            [matTooltip]="poiType.key"
            [checked]="selectedTypes.has(poiType.value)"
            [value]="poiType.value">
            <mat-icon [svgIcon]="'types:' + poiType.value" aria-label="POI Type"></mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <!-- Lists -->
      <mat-form-field appearance="standard" style="width: 100%">
        <mat-chip-list #lists>
            <mat-chip *ngFor="let selectedList of selectedLists$ | async" (removed)="listRemoved(selectedList)">
              <mat-icon matChipAvatar *ngIf="selectedList.fav">favorite</mat-icon>
              {{selectedList.name}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input #listInput matInput type="text" [formControl]="listControl" [matChipInputFor]="lists" [matAutocomplete]="listComplete" placeholder="Add lists by name" />
            <mat-icon matSuffix>terrain</mat-icon>
        </mat-chip-list>
        <mat-autocomplete #listComplete="matAutocomplete" (optionSelected)="listSelected($event.option.value)">
            <mat-option *ngFor="let list of filteredLists$ | async" [value]="list">
              <mat-icon *ngIf="list.fav">favorite</mat-icon>
              {{list.name}}
            </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <!-- Height -->
      <div id="height" style="display: flex; flex-direction: row">
        <mat-form-field  style="width: 100px">
          <input type="number" matInput [formControl]="minHeightControl" name="minHeightControl" placeholder="Min height" />
          <mat-icon matSuffix>vertical_align_bottom</mat-icon>
        </mat-form-field>
        <mat-form-field style="width: 100px; margin-left: 10px">
          <input type="number" matInput [formControl]="maxHeightControl" name="minHeightControl" placeholder="Max height" />
          <mat-icon matSuffix>vertical_align_top</mat-icon>
        </mat-form-field>
      </div>
 
    <!-- Countries -->
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-chip-list #countries>
          <mat-chip *ngFor="let selectedCountry of selectedCountries$ | async" (removed)="countryRemoved(selectedCountry)">
            <mat-icon matChipAvatar [svgIcon]="'flags:' + getFlagIcon(selectedCountry.code)"></mat-icon>
            {{selectedCountry.name}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input #countryInput matInput type="text" [formControl]="countryControl" [matChipInputFor]="countries" [matAutocomplete]="countryComplete" placeholder="Restrict to countries" />
          <mat-icon matSuffix>map</mat-icon>
      </mat-chip-list>
      <mat-autocomplete #countryComplete="matAutocomplete" (optionSelected)="countrySelected($event.option.value)">
          <mat-option *ngFor="let country of filteredCountries$ | async" [value]="country">
            <mat-icon [svgIcon]="'flags:' + getFlagIcon(country.code)"></mat-icon>
            {{country.name}}
          </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    </div>
  </div>

import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AppNavigationService } from './app-navigation.service';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UserService, ListService, Mode, PoiType } from './api';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';

enum AppState {
  LOADING,
  READY,
  ERROR,
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'Summit Tracker v3';
  @ViewChild(MatSidenav) sidenav: MatSidenav;

  AppState = AppState;
  readyState = AppState.LOADING;
  error: any;

  constructor(
    private appNavigation: AppNavigationService,
    authService: AuthService,
    userService: UserService,
    listService: ListService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {
      authService.userId.subscribe(
        () => {
          this.readyState = AppState.LOADING;
          forkJoin([
            userService.load(),
            listService.load()
          ]).subscribe(
            () => this.readyState = AppState.READY,
            (error) => {
              this.readyState = AppState.ERROR;
              this.error = error;
            });
        },
        (error) => {
          this.readyState = AppState.ERROR;
          this.error = 'Unable to contact authentication service.';
        }
      );

      for (const mode of Object.keys(Mode)) {
        this.matIconRegistry.addSvgIconInNamespace(
          'modes', Mode[mode],
          this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/mode-${Mode[mode]}.svg`));
      }
      for (const type of Object.keys(PoiType)) {
        this.matIconRegistry.addSvgIconInNamespace(
          'types', PoiType[type],
          this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/type-${PoiType[type].toLowerCase()}.svg`));
      }
  }

  ngAfterViewInit() {
    this.appNavigation.toggleSidenav$.subscribe(() => this.sidenav.toggle());
    this.router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe(() => {
      this.sidenav.close();
    });
  }
}

import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { GoogleMapControlComponent } from '../google-map-control/google-map-control.component';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { BaseMapType, OverlayType } from '../../map-type-manager.service';

@Component({
  selector: 'app-layer-control',
  templateUrl: './layer-control.component.html',
  styleUrls: ['./layer-control.component.scss']
})
export class LayerControlComponent extends GoogleMapControlComponent implements OnInit {
  @Input() baseMapTypes: BaseMapType[];
  @Input() selectedBaseMapType: BaseMapType;
  @Input() overlayTypes: OverlayType[];
  @Input() selectedOverlayTypes: OverlayType[];

  @Output() baseMapChanged = new EventEmitter<BaseMapType>();
  @Output() overlayTypesChanged = new EventEmitter<OverlayType[]>();

  expanded = false;

  constructor(el: ElementRef) {
    super(el);
  }

  ngOnInit(): void {
  }

  selectBaseMap($event: MatButtonToggleChange) {
    const baseMapType: BaseMapType = $event.value;
    this.baseMapChanged.emit(baseMapType);
  }

  selectOverlays($event: MatButtonToggleChange) {
    const overlayTypes: OverlayType[] = $event.value;
    this.overlayTypesChanged.emit(overlayTypes);
  }
}

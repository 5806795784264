<div *ngIf="user; then signedIn else signedOut"></div>
<ng-template #signedOut>
    <button mat-flat-button color="primary" (click)="signIn()">
        SIGN IN
    </button>
</ng-template>
<ng-template #signedIn>
    <div>
        Signed In: {{user.name}}
    </div>
</ng-template>

/**
 * Summit Tracker API
 * Provides POIs and user configuration for Summit Tracker
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PoiType = 'SUMMIT' | 'HUT' | 'PASS';

export const PoiType = {
    Summit: 'SUMMIT' as PoiType,
    Hut: 'HUT' as PoiType,
    Pass: 'PASS' as PoiType
};


import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { User } from '.';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService extends EntityCollectionServiceBase<User> {

  following$: Observable<User[]>;
  pendingFollowing$: Observable<User[]>;

  followers$: Observable<User[]>;
  pendingFollowers$: Observable<User[]>;

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('User', serviceElementsFactory);

    this.following$ = this.entities$.pipe(
      map(entities => entities.filter(user => user.following === 'approved')));
    this.pendingFollowing$ = this.entities$.pipe(
      map(entities => entities.filter(user => user.following === 'pending')));
    this.followers$ = this.entities$.pipe(
      map(entities => entities.filter(user => user.follower === 'approved')));
    this.pendingFollowers$ = this.entities$.pipe(
      map(entities => entities.filter(user => user.follower === 'pending')));
  }
}

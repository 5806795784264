<h2 mat-dialog-title>{{list.id ? "Edit " : "Create"}} list "{{list.name || ("(untitled)")}}"</h2>
<mat-dialog-content>
<form [formGroup]="formGroup">
<p>
    <mat-form-field>
        <mat-label>
            Name
        </mat-label>
        <input matInput type="text" formControlName="name" />
        <mat-icon matSuffix>
            terrain
        </mat-icon>
    </mat-form-field>
</p>
<p>
    <mat-form-field>
        <mat-label>
            Description
        </mat-label>
        <textarea matInput formControlName="description" rows="7">
        </textarea>
        <mat-icon matSuffix>
            description
        </mat-icon>
    </mat-form-field>
</p>
<p>
    <mat-form-field>
        <mat-label>
            Link to extended description
        </mat-label>
        <input matInput type="text" formControlName="link" />
        <mat-icon matSuffix>
            link
        </mat-icon>
    </mat-form-field>
</p>
</form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="null">Cancel</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button color="primary" [mat-dialog-close]="list">Save</button>
</mat-dialog-actions>
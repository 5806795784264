import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppNavigationService {
  isPortrait: Observable<boolean>;

  constructor(private breakpointObserver: BreakpointObserver) {
    const isPortraitSubject = new BehaviorSubject<boolean>(breakpointObserver.isMatched('(orientation: portrait)'));
    breakpointObserver.observe([
      '(orientation: portrait)',
      '(orientation: landscape)'
    ]).subscribe(() => {
      isPortraitSubject.next(
        breakpointObserver.isMatched('(orientation: portrait)'));
    });
    this.isPortrait = isPortraitSubject.pipe(delay(0));
  }

  private toggleSidenavSource = new Subject<void>();

  // Observable string streams
  toggleSidenav$ = this.toggleSidenavSource.asObservable();

  public toggleSidenav(): void {
    this.toggleSidenavSource.next();
  }
}


export function getOpenTopoMap(): google.maps.MapType {
    return new google.maps.ImageMapType({
        maxZoom: 16,
        minZoom: 0, // restrict zoom level so we don't burn tiles for top-level maps which are of little benefit
        name: 'OSM - Topo',
        tileSize: new google.maps.Size(256, 256),
        // credit: '© OpenStreetMap-Mitwirkende, SRTM | Kartendarstellung: © OpenTopoMap (CC-BY-SA)',
        getTileUrl(coord, zoom) {
                    return `https://a.tile.opentopomap.org/${zoom}/${coord.x}/${coord.y}.png`;
                },
    });
}

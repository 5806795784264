<div id="header" class="st-primary">
  <!-- TODO: Add photo grid. -->
  <mat-icon id="picture" class="icon">
    playlist_add_check
  </mat-icon>
  <div class="text">
    <h1>Lists</h1>
    <p>
      Manage lists to bag/track.
    </p>
  </div>
</div>
<button mat-fab color="primary" class="st-sideactionfab" (click)="editOrCreate()">
  <mat-icon>
    <!-- TODO: Find a more appropriate icon. -->
    playlist_add
  </mat-icon>
</button>
<mat-nav-list>
  <ng-container *ngIf="user">
    <h3 matSubheader>Custom Lists</h3>
    <ng-container *ngFor="let list of userLists$ | async" [ngTemplateOutlet]="listItem" [ngTemplateOutletContext]="{list: list}"></ng-container>
    <mat-divider></mat-divider>
    <h3 matSubheader>Summit Lists</h3>
  </ng-container>
  <div *ngIf="(filteredSystemLists$ | async).length > 0" style="display: flex; flex-direction: row; padding: 3px 6px">
    <mat-form-field style="flex-grow: 1">
      <input matInput type="text" [formControl]="filterControl" />
      <mat-icon matSuffix>
        filter_list
      </mat-icon>
    </mat-form-field>
  </div>
  <ng-container *ngFor="let list of filteredSystemLists$ | async" [ngTemplateOutlet]="listItem" [ngTemplateOutletContext]="{list: list}"></ng-container>
</mat-nav-list>

<ng-template #listItem let-list="list">
  <mat-list-item>
    <a matLine [routerLink]="['/list/', list.id]">{{list.name}}</a>
    <button mat-icon-button (click)="toggleFav(list)">
        <mat-icon>{{list.fav ? 'favorite' : 'favorite_border'}}</mat-icon>
    </button>
    <button mat-icon-button *ngIf="canEdit(list)" (click)="editOrCreate(list)">
      <mat-icon>edit</mat-icon>
    </button>
    <button mat-icon-button *ngIf="canDelete(list)" (click)="deleteList(list)">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-list-item>
</ng-template>

<ng-template #deleteConfirmationDialog let-data>
  <h2 mat-dialog-title>Delete List</h2>
  <mat-dialog-content>
    Are you sure you want to delete list {{data.list.name}}?
  </mat-dialog-content>
  <mat-dialog-actions>
      <button mat-button mat-dialog-close color="primary">No</button>
      <button mat-button [mat-dialog-close]="true">Yes</button>
  </mat-dialog-actions>
</ng-template>
<mat-toolbar color="primary">
    <button mat-icon-button (click)="toggleSidenav()">
        <mat-icon>
            menu
        </mat-icon>
    </button>
    <span>Summit Tracker</span>
</mat-toolbar>

<div style="margin: 16px 32px">
    <router-outlet>
    </router-outlet>
</div>

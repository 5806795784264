/**
 * Summit Tracker API
 * Provides POIs and user configuration for Summit Tracker
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InlineResponse2001 { 
    /**
     * How many new entries were added.
     */
    added_count?: number;
}


import { Component, OnInit, ViewChild, AfterViewInit, NgZone, TemplateRef } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';

import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { DefaultService as DefaultApi, ClusteredPoi, MarkerResponse, PoiService, Poi, User, PoiType, LogService, ListService } from '../../api';
import { Observable, ReplaySubject, combineLatest, of, EMPTY, merge } from 'rxjs';
import { UserControlComponent } from '../controls/user-control/user-control.component';
import { MapService, MapState } from '../map.service';
import { distinctUntilChanged, map, withLatestFrom, debounceTime, take, delay, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { AppNavigationService } from 'src/app/app-navigation.service';
import { EntityOp } from '@ngrx/data';

import { getClusterIcon, getPoiIcon } from './markers';
import { getSwissTopoLayer } from '../layers/ch';
import { getOpenTopoMap } from '../layers/opentopomap';
import { getNationalTopoMap } from '../layers/nationaltopo';

import { LayerControlComponent } from '../controls/layer-control/layer-control.component';
import { MapTypeManagerService, BaseMapType, OverlayType } from '../map-type-manager.service';

enum PoiMarkerCompletion {
    NONE,
    SOME,
    ALL
}

enum GpsState {
    UNKNOWN,
    READY,
    ERROR,
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {
    constructor(
        public app: AppNavigationService,
        private authService: AuthService,
        private bottomSheet: MatBottomSheet,
        private poiApi: DefaultApi,
        private listService: ListService,
        private logService: LogService,
        private mapService: MapService,
        private mapTypeManagerService: MapTypeManagerService,
        private poiService: PoiService,
        private route: ActivatedRoute,
        private router: Router,
        private snackBar: MatSnackBar,
        private ngZone: NgZone) { }
    @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
    @ViewChild(UserControlComponent) userControl: UserControlComponent;
    @ViewChild(LayerControlComponent) layerControl: LayerControlComponent;
    @ViewChild('bottomSheetOutlet') bottomSheetOutlet: TemplateRef<any>;
    @ViewChild('primaryOutlet') primaryOutlet: RouterOutlet;

    GpsState = GpsState;

    multiselect = false;
    loading = false;
    bottomSheetRef: MatBottomSheetRef = null;
    routeActive = false;
    mapOptions: google.maps.MapOptions = {
        scaleControl: true,
        mapTypeControl: false,
        fullscreenControl: false,
        mapTypeId: 'opentopo',
        center: { lat: 54.85, lng: -4.262 },
        zoom: 5,
    };
    baseMapTypes = [{
        id: 'opentopo',
        name: 'OpenTopo',
        description: 'OpenStreetMap Topo rendering, with contours',
        imgUrl: '/assets/icons/map-opentopo.png',
    }, {
        id: 'nationaltopo',
        name: 'Nat. Topo',
        description: 'National Topo Maps (OS, SwissTopo)',
        imgUrl: '/assets/icons/map-nationaltopo.png',
    }, {
        id: 'terrain',
        name: 'Terrain',
        description: 'Google Maps Terrain',
        imgUrl: '/assets/icons/map-terrain.png',
    }, {
        id: 'satellite',
        name: 'Satellite',
        description: 'Google Maps Satellite',
        imgUrl: '/assets/icons/map-satellite.png',
    }];
    selectedBaseMapType: BaseMapType = this.baseMapTypes[0];

    overlayTypes = [{
        id: 'hiking',
        name: 'Hiking',
        description: 'Hiking routes, where available',
        imgUrl: '/assets/icons/overlay-hiking.png',
        exclusive: false,
    }, {
        id: 'cycling',
        name: 'Cycling',
        description: 'Cycling routes, where available',
        imgUrl: '/assets/icons/overlay-cycling.png',
        exclusive: false,
    }];
    selectedOverlayTypes: OverlayType[] = [];

    gpsState = GpsState.UNKNOWN;

    /**
     * A stream of bounds from the map.
     */
    private bounds$ = new ReplaySubject<google.maps.LatLngBounds>(1);

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.map.mapTypes.set('opentopo', getOpenTopoMap());
        const sub = this.map.projectionChanged.subscribe(() => {
            if (!sub.closed) {
                sub.unsubscribe();
                this.map.mapTypes.set('nationaltopo', getNationalTopoMap(this.map.getProjection()));
            }
        });
        this.map.options = this.mapOptions;
        this.map.data.setStyle(this.styleFeature.bind(this));

        this.userControl.addToGoogleMap(this.map, google.maps.ControlPosition.TOP_RIGHT);
        this.layerControl.addToGoogleMap(this.map, google.maps.ControlPosition.RIGHT_BOTTOM);

        this.map.data.addListener('click', $event => {
            this.ngZone.run( () => this.doClick($event));
        });

        this.mapService.state$.subscribe(state => {
            this.map.center = state.center;
            this.map.zoom = state.zoom;
        });

        // Subscribe only after the first emission of state.
        this.mapService.state$.pipe(take(1), delay(0)).subscribe(() => {
            this.map.idle.subscribe(() => this.mapIdle());
        });

        this.mapService.selection$.subscribe(selection => {
            this.setSelectedFeatures(selection);
            if (selection.length > 0) {
                const bounds = new google.maps.LatLngBounds();
                for (const poi of selection) {
                    bounds.extend({lat: poi.lat, lng: poi.lon});
                }
                // If the bounds are outside, pan to them (which can also zoom).
                // Then move to the center.
                // Otherwise, center the point.
                if (!this.map.getBounds() || !this.map.getBounds().contains(bounds.getCenter())) {
                    this.map.panTo(bounds.getCenter());
                    if (this.map.getZoom() < 12) {
                        this.map.zoom = 12;
                    }
                } else {
                    this.map.panToBounds(bounds, 150);
                }
            }
            if (selection.length > 1) {
                this.router.navigate(
                    ['/pois', selection.map(poi => poi.id).join(',')],
                    {
                        relativeTo: this.route,
                        queryParamsHandling: 'preserve', // remove to replace all query params by provided
                    });
            }
            else if (selection.length === 1) {
                this.router.navigate(
                    ['/poi', selection[0].id],
                    {
                        relativeTo: this.route,
                        queryParamsHandling: 'preserve', // remove to replace all query params by provided
                    });
            }
        });



        // TODO: Change bounds expansion to take into account zoom level.
        const loadOnStateBoundsChange: Observable<[MapState, google.maps.LatLngBounds]> = combineLatest([
            this.mapService.state$,
            this.bounds$,
            this.authService.user]).pipe(
            debounceTime(100),
            // Expand the bounds so we don't have to load on every scroll. This uses ceil/floor
            // so we can do the map first. If we change the algorithm, we'll have to move the
            // map after distinct.
            map<[MapState, google.maps.LatLngBounds, User], [MapState, google.maps.LatLngBounds, User]>(
                ([state, bounds, user]) => ([state, this.expandBounds(bounds), user])),
            distinctUntilChanged(([oldState, oldBounds, oldUser], [newState, newBounds, newUser]) => {
                return !newState.forceRefresh && oldState.users.map(u => u.id).join(',') === newState.users.map(u => u.id).join(',') &&
                    oldState.lists.map(l => l.id).join(',') === newState.lists.map(l => l.id).join(',') &&
                    oldState.countries.map(c => c.code).join(',') === newState.countries.map(c => c.code).join(',') &&
                    oldState.types.size === newState.types.size && [...oldState.types].every(v => newState.types.has(v)) &&
                    oldState.minHeight === newState.minHeight &&
                    oldState.maxHeight === newState.maxHeight &&
                    oldState.zoom === newState.zoom &&
                    oldUser?.id === newUser?.id &&
                    // Keep the same if the next step would load the same bounds.
                    oldBounds.contains(newBounds.getNorthEast())
                        && oldBounds.contains(newBounds.getSouthWest());
            }),
            map(([state, bounds, user]) => ([state, bounds]))
        );
        // TODO: Take into account list changes as well.
        const loadOnEntityChange: Observable<[MapState, google.maps.LatLngBounds]> = merge(
            this.poiService.entityActions$,
            this.listService.entityActions$,
            this.logService.entityActions$).pipe(
            withLatestFrom(this.mapService.state$, this.bounds$),
            filter(([action, state, bounds]) => {
                const updateTypes = [
                    EntityOp.SAVE_ADD_MANY_SUCCESS,
                    EntityOp.SAVE_ADD_ONE_SUCCESS,
                    EntityOp.SAVE_DELETE_ONE_SUCCESS,
                    EntityOp.SAVE_DELETE_MANY_SUCCESS,
                    EntityOp.SAVE_UPDATE_ONE_SUCCESS,
                    EntityOp.SAVE_UPDATE_MANY_SUCCESS,
                    EntityOp.SAVE_UPSERT_MANY_SUCCESS,
                    EntityOp.SAVE_UPSERT_ONE_SUCCESS];
                return !!(state && bounds && updateTypes.includes(action.payload.entityOp));
            }),
            map(([action, state, bounds]) => ([state, bounds]))
        );
        merge(loadOnStateBoundsChange, loadOnEntityChange).pipe(
            tap(() => this.loading = true),
            switchMap(([state, bounds]) => {
                return this.loadMarkers(state, bounds).pipe(
                    catchError((error) => {
                        this.loading = false;
                        this.snackBar.open(
                            `Unable to load map markers (${(error as HttpErrorResponse).statusText})`,
                            null,
                            {duration: 3000});
                        return EMPTY;
                    })
                );
            }),
        ).subscribe(([response, selection, mapState]) => {
            this.addFeaturesToMap(mapState, response, selection);
            this.loading = false;
        });

        // Geo location.
        this.mapService.location$.pipe(
            distinctUntilChanged((oldPos, newPos) => oldPos.coords.latitude === newPos.coords.latitude
                && oldPos.coords.longitude === newPos.coords.longitude),
        ).subscribe(
            (position) => {
                if (!this.map) {
                    return;
                }
                this.gpsState = GpsState.READY;
                const featureOptions = {
                    id: 'GEOLOCATION',
                    geometry: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    }
                };
                this.map.data.add(featureOptions);
            },
            (error) => {
                this.gpsState = GpsState.ERROR;
            });

        this.mapTypeManagerService.setBaseMapTypes(this.baseMapTypes);
        this.mapTypeManagerService.setOverlayTypes(this.overlayTypes);
        this.mapTypeManagerService.baseMapType$.subscribe(this.baseMapChanged.bind(this));
        this.mapTypeManagerService.overlayTypes$.subscribe(this.overlaysChanged.bind(this));
    }

    // TODO: Move API call into PoiService and abstract away the query parameter joins/bounding box.
    private loadMarkers(mapState: MapState, bounds: google.maps.LatLngBounds) {
        const bbx = [
            bounds.getNorthEast().lat(),
            bounds.getNorthEast().lng(),
            bounds.getSouthWest().lat(),
            bounds.getSouthWest().lng()];
        return this.poiApi.getMarkers(
            mapState.zoom,
            bbx.join(','),
            mapState.users.map(u => u.id).join(','),
            mapState.lists.map(l => l.id).join(','),
            Array.from(mapState.types).join(','),
            mapState.countries.map(c => c.code).join(','),
            mapState.minHeight, mapState.maxHeight).pipe(
                withLatestFrom(this.mapService.selection$, of(mapState)));
    }

    private addFeaturesToMap(mapState: MapState, response, selection: Poi[]) {
        const loaded = {};
        for (const cluster of response.clusters) {
            const id = cluster.id;
            const feature = loaded[cluster.id] = this.map.data.getFeatureById(id);
            const diff = !feature || cluster.c !== feature.getProperty('clusterSize');
            if (diff) {
                loaded[cluster.id] = this.map.data.add({
                    id: cluster.id,
                    geometry: {lat: cluster.lat, lng: cluster.lon},
                    properties: {
                        clusterSize: cluster.c,
                        bbx: cluster.bbx,
                    }
                });
            }
        }
        for (const poi of response.pois) {
            const id = poi.id;
            const feature = loaded[poi.id] = this.map.data.getFeatureById(id);
            const baggedCount = poi.bagged_count;
            const baggedOutOf = Math.max(mapState.users.length, 1);
            let markerCompletion = PoiMarkerCompletion.NONE;
            if (baggedCount === baggedOutOf) {
                markerCompletion = PoiMarkerCompletion.ALL;
            } else if (baggedCount > 0) {
                markerCompletion = PoiMarkerCompletion.SOME;
            }
            const diff = !feature || feature.getProperty('baggedOutOf') !== baggedOutOf ||
                feature.getProperty('baggedCount') !== baggedCount ||
                feature.getProperty('markerCompletion') !== markerCompletion;
            if (diff) {
                const extraProperties = {
                    baggedCount,
                    baggedOutOf,
                    markerCompletion,
                };
                const featureOptions = this.poiToFeature(poi, extraProperties);
                loaded[poi.id] = this.map.data.add(featureOptions);
            }
        }
        // Add any non-clusters.
        this.poiService.addManyToCache(response.pois);

        this.map.data.forEach((feature) => {
            loaded['GEOLOCATION'] = true;
            if (!feature.getProperty('selectedOnly') && !loaded[feature.getId()]) {
                this.map.data.remove(feature);
            }
        });
        this.setSelectedFeatures(selection);
    }

    private updateFeatureForPoi(poi: Poi) {

    }

    routeActivated() {
        this.routeActive = true;
    }

    routeDeactivated() {
        this.routeActive = false;
    }

    closeSidenavContent() {
        this.mapService.clearSelection();
        this.router.navigate(
            ['/'],
            {
                relativeTo: this.route,
                queryParamsHandling: 'preserve', // remove to replace all query params by provided
            });
    }

    goToLocation() {
        const feature = this.map.data.getFeatureById('GEOLOCATION');
        if (feature) {
            this.map.panTo(
                (feature.getGeometry() as google.maps.Data.Point).get());
            if (this.map.getZoom() < 12) {
                this.map.zoom = 12;
            }
        }
    }

    doClick($event: google.maps.Data.MouseEvent) {
        const feature = $event.feature;
        if (feature.getId() === 'GEOLOCATION') {
            this.goToLocation();
            return;
        }
        const bbx = feature.getProperty('bbx');
        const poi = feature.getProperty('poi');
        if (bbx) {
            this.map.fitBounds({
                north: bbx[0][0],
                east:  bbx[0][1] ,
                south: bbx[1][0],
                west:  bbx[1][1],
            });
        } else if (poi) {
            if (this.multiselect) {
                this.mapService.toggleSelection(poi);
            } else {
                this.mapService.setSelection([poi]);
            }
        }
    }

    selectBaseMap(baseMapType: BaseMapType) {
        this.mapTypeManagerService.selectBaseMap(baseMapType);
    }

    baseMapChanged(baseMapType: BaseMapType) {
        this.map.googleMap.setMapTypeId(baseMapType.id);
        this.selectedBaseMapType = baseMapType;
    }

    selectOverlays(overlayTypes: OverlayType[]) {
        this.mapTypeManagerService.selectOverlays(overlayTypes);
    }

    overlaysChanged(overlayTypes: OverlayType[]) {
        this.map.overlayMapTypes.clear();
        for (const overlayType of overlayTypes) {
            let overlay: google.maps.MapType;
            if (overlayType.id === 'hiking') {
                overlay = getSwissTopoLayer('hiking');
            } else  if (overlayType.id === 'cycling') {
                overlay = getSwissTopoLayer('cycling');
            }

            if (overlay) {
                this.map.overlayMapTypes.insertAt(0, overlay);
            } else {
                console.log('Unable to find map overlay', overlayType);
            }
        }
        this.selectedOverlayTypes = overlayTypes;
    }

    private expandBounds(bounds: google.maps.LatLngBounds) {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        return new google.maps.LatLngBounds(
            {
                lat: Math.floor(sw.lat()),
                lng: Math.floor(sw.lng()),
            },
            {
                lat: Math.ceil(ne.lat()),
                lng: Math.ceil(ne.lng()),
            },
            );
    }

    private styleFeature(feature: google.maps.Data.Feature): google.maps.Data.StyleOptions {
        if (feature.getId() === 'GEOLOCATION') {
            return {
                icon: {
                    url: `/assets/icons/target.svg`,
                },
                title: 'Your Location',
            };
        }
        const clusterSize = feature.getProperty('clusterSize');
        if (clusterSize > 1) {
            return {
                icon: {
                    url: getClusterIcon(clusterSize),
                    // TODO: Adjust this based on image size.
                    anchor: new google.maps.Point(25, 25),
                },
                title: `Cluster #${feature.getId()} of size ${clusterSize}`,
                zIndex: 10000,
            };
        } else {
            const poi = feature.getProperty('poi') as Poi;
            const baggedCount = feature.getProperty('baggedCount');
            const totalCount = feature.getProperty('baggedOutOf');
            return {
                icon: {
                    url: getPoiIcon(
                        poi?.poi_type,
                        baggedCount || 0,
                        totalCount || 0,
                        feature.getProperty('selected')),
                },
                title: poi?.name || `Point of interest ${feature.getId()}`,
            };
        }
    }

    mapIdle(): void {
        if (!this.map) {
            return;
        }
        // Bounds are relevant to the API loading, but not to the global map
        // state in the URL, so is tracked separately here.
        const bounds = this.map.getBounds();
        if (!bounds) {
            return;
        }
        this.bounds$.next(bounds);

        // Send an update about the map center and zoom resulting from the user panning.
        const center = this.map.getCenter();
        const requiredZoom = this.map.getZoom();
        this.mapService.updateMapCenterZoom({lat: center.lat(), lng: center.lng()}, requiredZoom);
    }

    private setSelectedFeatures(selection: Poi[]) {
        const selectedIds = selection.map(poi => poi.id);
        this.map.data.forEach((feature) => {
            const id = feature.getId() as string;
            const isSelected = selectedIds.includes(id);
            if (feature.getProperty('selected') !== isSelected) {
                feature.setProperty('selected', isSelected);
            }
            if (feature.getProperty('selectionOnly') && !isSelected) {
                this.map.data.remove(feature);
            }
        });
        for (const poi of selection) {
            const feature = this.map.data.getFeatureById(poi.id);
            if (!feature) {
                const extraProperties = {
                    selectionOnly: true,
                    selected: true,
                };
                const featureOptions = this.poiToFeature(poi, extraProperties);
                this.map.data.add(
                    featureOptions,
                );
            }
        }
    }

    /**
     * Create a basic marker configuration.
     */
    private poiToFeature(poi: Poi | ClusteredPoi, extraProperties?: object): google.maps.Data.FeatureOptions {
        return {
            id: poi.id,
            geometry: {lat: poi.lat, lng: poi.lon},
            properties: {...{
                poi,
                markerCompletion: PoiMarkerCompletion.NONE,
            }, ...extraProperties}
        };
    }

    openBottomSheet() {
        this.bottomSheetRef = this.bottomSheet.open(this.bottomSheetOutlet, {
            hasBackdrop: false,
            panelClass: 'st-card-sheet',
        });
        this.bottomSheetRef.afterDismissed().subscribe(() => {
            this.bottomSheetRef = null;
            this.closeSidenavContent();
        });
    }
}

import { Injectable } from '@angular/core';
import { User, UserService, List, ListService, Poi, PoiType } from '../api';
import { Observable, BehaviorSubject, combineLatest, merge, ReplaySubject, Subject } from 'rxjs';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map, shareReplay, tap, withLatestFrom, delay, distinctUntilChanged } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { CountryService, Country } from '../country.service';

export interface LatLng {
  lat: number;
  lng: number;
}

/**
 * Encodes the state of the map and applied filters.
 */
export interface MapState {
  // Set to true if the map should be
  // refreshed, regardless if the other
  // fields have changed.
  forceRefresh: boolean;
  center: LatLng;
  zoom: number;
  users: User[];
  lists: List[];
  types: Set<PoiType>;
  countries: Country[];
  minHeight?: number;
  maxHeight?: number;
}

/**
 * Manages the state of the map and applied filters.
 * This service will preload the state store with users
 * and lists.
 */
@Injectable({
  providedIn: 'root'
})
export class MapService {
  private defaults = {
    center: { lat: 54.85, lng: -4.262 },
    zoom: 5,
    users: [],
    lists: [],
    types: new Set<PoiType>(),
    countries: [],
  };
  private selection = new BehaviorSubject<Poi[]>([]);
  /**
   * Emits whenever the map state should be forcefully
   * refreshed.
   */
  private refresh$ = new Subject<void>();
  private currentState: BehaviorSubject<MapState | null> = new BehaviorSubject(null);
  private currentLocation = new ReplaySubject<GeolocationPosition>(1);

  state$: Observable<MapState>;
  selection$: Observable<Poi[]> = this.selection.asObservable();
  location$: Observable<GeolocationPosition> = this.currentLocation.asObservable();

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private listService: ListService,
    private cookies: CookieService,
    private countryService: CountryService,
    private route: ActivatedRoute,
    private router: Router) {
      /**
       * Convert router parameter updates into map state updates.
       */
      const updatingState$ = combineLatest(
        [
          this.route.queryParamMap,
          this.userService.entityMap$,
          this.listService.entityMap$,
          this.authService.user
        ]).pipe(map(([params, availableUsers, availableLists, loggedInUser]) => {
        // TODO: Sync cookies back to URL.
        // The map center is selected from the URL, a cookie or defaults,
        // in that order.
        let center = this.defaults.center;
        let zoom = this.defaults.zoom;
        const encodedMapSettings = params.has('m') ? params.get('m') : this.cookies.get('m');
        if (encodedMapSettings) {
          const [lat, lng, zm] = encodedMapSettings.split(',', 3);
          center = {lat: Number(lat), lng: Number(lng)};
          zoom = Number(zm);
        }
        // Users.
        const users = [];
        const encodedUsers = params.has('u') ? params.get('u') : this.cookies.get('u');
        if (encodedUsers) {
          const userIds = encodedUsers.split(',');
          for (const id of userIds) {
            const user = availableUsers[id] || { id };
            users.push(user);
          }
        }
        if (!users.length && loggedInUser) {
          users.push(loggedInUser);
        }
        // Lists.
        const lists = [];
        const encodedLists = params.has('ls') ? params.get('ls') : this.cookies.get('ls');
        if (encodedLists) {
          const listIds = encodedLists.split(',');
          for (const id of listIds) {
            const list = availableLists[id] || { id };
            lists.push(list);
          }
        }
        // Types.
        let types = this.defaults.types;
        const encodedTypes = params.has('t') ? params.get('t') : this.cookies.get('t');
        if (encodedTypes) {
          const typeValues = encodedTypes.toUpperCase().split(',');
          types = new Set<PoiType>();
          for (const poiType in PoiType) {
            if (typeValues.includes(poiType)) {
              types.add(poiType as PoiType);
            }
          }
        }
        // Country Filter.
        const countries = [];
        const encodedCountries = params.has('c') ? params.get('c') : this.cookies.get('c');
        if (encodedCountries) {
          const countryCodes = encodedCountries.split(',');
          for (const countryCode of countryCodes) {
            const country = this.countryService.isoCodesMap.get(countryCode.toUpperCase());
            if (country) {
              countries.push(country);
            }
          }
        }
        // Country Filter.
        let maxHeight: number = null;
        let minHeight: number = null;
        const encodedHeight = params.has('h') ? params.get('h') : this.cookies.get('h');
        if (encodedHeight) {
          const heightRange = encodedHeight.split(',');
          if (heightRange.length === 2) {
            if (heightRange[0]) {
              minHeight = +heightRange[0];
            }
            if (heightRange[1]) {
              maxHeight = +heightRange[1];
            }
          }
        }
        // Ensure the URL aligns with the state.
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            m: encodedMapSettings || null,
            u: encodedUsers || null,
            ls: encodedLists || null,
            c: encodedCountries || null,
            t: encodedTypes || null,
            h: encodedHeight || null,
          },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
        // Return.
        const mapState = {
          forceRefresh: false,
          center,
          zoom,
          users,
          lists,
          types,
          countries,
          maxHeight,
          minHeight,
        };
        return mapState;
      }), shareReplay(1));
      // TODO: Remove this subject by making MapState only emit when changed.
      const forceRefreshState$: Observable<MapState> = this.refresh$.pipe(
        withLatestFrom(updatingState$),
        map(([_, state]) =>  ({
          ...state,
          forceRefresh: true
        })),
      );
      this.state$ = merge(updatingState$, forceRefreshState$);
      this.state$.subscribe(this.currentState);

      // Geolocation.
      if (navigator.geolocation) {
        navigator.geolocation.watchPosition(
          (position) => this.currentLocation.next(position),
          (error) => this.currentLocation.error(error),
          // 60 minutes.
          {maximumAge: 60 * 60 * 1000}
        );
      } else {
        this.currentLocation.error('Browser does not support geolocation.');
      }
  }

  /**
   * Update the map URL state with center and zoom.
   */
  updateMapCenterZoom(center: LatLng, zoom: number) {
    const m = `${center.lat},${center.lng},${zoom}`;
    this.cookies.set('m', m, null, '/');
    this.router.navigate(
      [],
      {
          relativeTo: this.route,
          queryParams: { m },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
          replaceUrl: true, // We don't want the back button to move the map.
      });
  }

  /**
   * Adds a user to the filter options and map state.
   */
  addUser(user: User) {
    const state = this.currentState.getValue();
    if (!state) {
      throw new Error('addUser called before state was initialized from URL');
    }
    if (!state.users.some(u => u.id === user.id)) {
      this.updateUrlUsers(state.users.concat([user]));
    }
  }

  /**
   * Removes a user from the filter options and map state.
   * Succeeds if the user doesn't exist in the filter.
   */
  removeUser(user: User) {
    const state = this.currentState.getValue();
    if (!state) {
      throw new Error('removeUser called before state was initialized from URL');
    }
    this.updateUrlUsers(state.users.filter(u => u.id !== user.id));
  }

  /**
   * Update the URL with the current set of users.
   * @param users The set of users to store in the URL.
   */
  private updateUrlUsers(users: User[]) {
    let u = null;
    if (users.length) {
      u = users.map(user => user.id).join(',');
    }
    this.cookies.set('u', u || '', null, '/');
    this.router.navigate(
      [],
      {
          relativeTo: this.route,
          queryParams: { u },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
          replaceUrl: false,
      });
  }

  /**
   * Adds a list to the filter options and map state.
   */
  addList(list: List) {
    const state = this.currentState.getValue();
    if (!state) {
      throw new Error('addList called before state was initialized from URL');
    }
    if (!state.lists.some(l => l.id === list.id)) {
      this.updateUrlLists(state.lists.concat([list]));
    }
  }

  /**
   * Removes a list from the filter options and map state.
   * Succeeds if the list doesn't exist in the filter.
   */
  removeList(list: List) {
    const state = this.currentState.getValue();
    if (!state) {
      throw new Error('removeList called before state was initialized from URL');
    }
    this.updateUrlLists(state.lists.filter(l => l.id !== list.id));
  }

  /**
   * Update the URL with the current set of users.
   * @param lists The set of users to store in the URL.
   */
  private updateUrlLists(lists: List[]) {
    let ls = null;
    if (lists.length) {
      ls = lists.map(l => l.id).join(',');
    }
    this.cookies.set('ls', ls || '', null, '/');
    this.router.navigate(
      [],
      {
          relativeTo: this.route,
          queryParams: { ls },
          queryParamsHandling: 'merge',
          replaceUrl: false, // back button will not undo the add.
      });
  }

  /**
   * Adds a country to the filter options and map state.
   */
  addCountry(country: Country) {
    const state = this.currentState.getValue();
    if (!state) {
      throw new Error('addCountry called before state was initialized from URL');
    }
    if (!state.countries.some(c => c.code === country.code)) {
      this.updateUrlCountries(state.countries.concat([country]));
    }
  }

  /**
   * Removes a list from the filter options and map state.
   * Succeeds if the list doesn't exist in the filter.
   */
  removeCountry(country: Country) {
    const state = this.currentState.getValue();
    if (!state) {
      throw new Error('removeCountry called before state was initialized from URL');
    }
    this.updateUrlCountries(state.countries.filter(c => c.code !== country.code));
  }

  /**
   * Update the URL with the current set of users.
   * @param lists The set of users to store in the URL.
   */
  private updateUrlCountries(countries: Country[]) {
    let c = null;
    if (countries.length)
    {
      c = countries.map(country => country.code).join(',');
    }
    this.cookies.set('c', c || '', null, '/');
    this.router.navigate(
      [],
      {
          relativeTo: this.route,
          queryParams: { c },
          queryParamsHandling: 'merge',
          replaceUrl: false,
      });
  }

  /**
   * Adds a POI type to the filter options and map state.
   */
  addPoiType(type: PoiType) {
    const state = this.currentState.getValue();
    if (!state) {
      throw new Error('addPoiType called before state was initialized from URL');
    }
    if (!state.types.has(type)) {
      const types = new Set(state.types);
      this.updateUrlTypes(types.add(type));
    }
  }

  /**
   * Removes a type from the filter options and map state.
   * Succeeds if the type doesn't exist in the filter.
   */
  removePoiType(type: PoiType) {
    const state = this.currentState.getValue();
    if (!state) {
      throw new Error('removePoiType called before state was initialized from URL');
    }
    const types = new Set(state.types);
    types.delete(type);
    this.updateUrlTypes(types);
  }

  /**
   * Sets the types in the filter and map state.
   * Succeeds if the type doesn't exist in the filter.
   */
  setPoiTypes(types: Set<PoiType>) {
    this.updateUrlTypes(types);
  }


  /**
   * Update the URL with the current set of users.
   * @param lists The set of users to store in the URL.
   */
  private updateUrlTypes(types: Set<PoiType>) {
    const typesAsString: Array<string> = Array.from(types.values());
    let t = null;
    if (typesAsString.length)
    {
      t = typesAsString.join(',');
    }
    this.cookies.set('t', t || '', null, '/');

    this.router.navigate(
      [],
      {
          relativeTo: this.route,
          queryParams: { t },
          queryParamsHandling: 'merge',
          replaceUrl: false,
      });
  }

  setHeightRange(minHeight: number, maxHeight: number) {
    let h = null;
    if (minHeight || maxHeight) {
      h = (minHeight || '') + ',' + (maxHeight || '');
    }
    this.cookies.set('h', h || '', null, '/');
    this.router.navigate(
      [],
      {
          relativeTo: this.route,
          queryParams: { h, },
          queryParamsHandling: 'merge',
          replaceUrl: false,
      });
  }

  toggleSelection(poi: Poi) {
    if (this.selection.value.includes(poi)) {
      this.removeFromSelection([poi]);
    } else {
      this.addToSelection([poi]);
    }
  }

  setSelection(pois: Poi[]) {
    this.selection.next(pois);
  }

  clearSelection() {
    this.setSelection([]);
  }

  addToSelection(pois: Poi[]) {
    this.selection.next(this.selection.value.concat(pois));
  }

  removeFromSelection(pois: Poi[]) {
    this.selection.next(this.selection.value.filter(poi => !pois.includes(poi)));
  }

  /**
   * The system state has been updated in a way which
   * requires a map refresh.
   */
  refresh() {
    this.refresh$.next();
  }
}

<h1>
    Connections
</h1>
<p>
    <button mat-flat-button color="accent" (click)="sync()">
        Sync Now
    </button>
    <mat-progress-spinner *ngIf="syncing" color="primary" mode="indeterminate" diameter="24" style="display: inline-block">
    </mat-progress-spinner>
</p>
<div>
<mat-tab-group>
    <mat-tab label="hill-bagging.co.uk">
        <form [formGroup]="dobihGroup" autocomplete="off">
            <p>
                <img src="https://www.hill-bagging.co.uk/images/WhiteSheep.gif" style="float: right" />
                <a href="http://www.hill-bagging.co.uk" target="_blank">hill-bagging.co.uk</a> is the online version of the Database of British & Irish Hills.
            </p>
            <p>
                There are two types of password: one for read-only access, and one to full access.
                Entering your read-only password means we can import data, but not export it back.
            </p>
            <p>
                You can set your "Read-only" password on the <a href="https://www.hill-bagging.co.uk/register.php">"My details"</a> page.
            </p>
            <p>
                <mat-form-field>
                    <input required matInput type="text" placeholder="Enter your username" formControlName="dobih_username" autocomplete="nickname" name="dobih_username" />
                    <mat-icon matSuffix>person</mat-icon>
                </mat-form-field>
            </p>
            <p>
                <!-- TODO: Make this reflect if the backend has a password set -->
                <mat-form-field>
                    <input matInput type="password" placeholder="Enter your password" formControlName="dobih_password" autocomplete="new-password" name="dobih_password" />
                    <mat-icon matSuffix>vpn_key</mat-icon>
                </mat-form-field>
            </p>
        </form>
        <button mat-flat-button color="primary" [disabled]="!dobihGroup.valid || !dobihGroup.touched" (click)="save(dobihGroup.value)">Save</button>
    </mat-tab>
    <mat-tab label="Walkhighlands">
        <form [formGroup]="walkhighlandsGroup">
            <mat-form-field>
                <input matInput type="number" placeholder="Enter your Walkhighlands ID" formControlName="walkhighlands_id" name="walkhighlands_id" />
                <mat-icon matSuffix>link</mat-icon>
            </mat-form-field>
        </form>
        <button mat-flat-button color="primary" [disabled]="!walkhighlandsGroup.valid || !walkhighlandsGroup.touched" (click)="save(walkhighlandsGroup.value)">Save</button>
    </mat-tab>
</mat-tab-group>
</div>
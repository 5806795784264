import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { switchMap, take } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {


    constructor(private user: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.user.getUserIdToken().pipe(
            take(1),
            switchMap(token => {
                if (token) {
                    request = request.clone({
                        setHeaders: {
                        Authorization: `Bearer ${token}`
                        }
                    });
                }

                return next.handle(request);
            })
        );
    }
}

import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { AppNavigationService } from 'src/app/app-navigation.service';
import { FormControl } from '@angular/forms';
import { UserService, User, Poi, PoiService, ListService, List, PoiType } from 'src/app/api';
import { Observable, combineLatest, of } from 'rxjs';
import { debounceTime, switchMap, map, distinctUntilChanged, tap } from 'rxjs/operators';
import { CountryService, Country } from 'src/app/country.service';
import { AnimationTriggerMetadata, trigger, style, state, transition, animate, keyframes } from '@angular/animations';
import { MapService, MapState } from '../map.service';
import { AvatarSize, PhotoService } from 'src/app/photo.service';
import { AuthService } from 'src/app/auth.service';
import { MergeStrategy } from '@ngrx/data';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

const EXPANSION_PANEL_ANIMATION_TIMING = '225ms cubic-bezier(0.4,0.0,0.2,1)';
const panelExpansion: AnimationTriggerMetadata = trigger('panelExpansion', [
  state('collapsed, void', style({height: '0px', visibility: 'hidden', 'padding-top': '0px', 'padding-bottom': '0px'})),
  state('expanded', style({height: '*', visibility: 'visible', padding: '*'})),
  transition('expanded <=> collapsed, void => collapsed',
    animate(EXPANSION_PANEL_ANIMATION_TIMING)),
]);

@Component({
  selector: 'app-search-overlay',
  templateUrl: './search-overlay.component.html',
  styleUrls: ['./search-overlay.component.scss'],
  animations: [panelExpansion],
})
export class SearchOverlayComponent implements OnInit {

  constructor(
    private appNavigation: AppNavigationService,
    private authService: AuthService,
    private countries: CountryService,
    private listService: ListService,
    private userService: UserService,
    private mapService: MapService,
    private poiService: PoiService,
    private photos: PhotoService,
    ) {
  }
  @Input() closeAllowed: boolean;
  @Input() locateAllowed: boolean;
  @Input() locateError: boolean;
  @Output() closeClick = new EventEmitter<void>();
  @Output() locateClick = new EventEmitter<void>();

  @ViewChild('peopleInput') peopleInput: ElementRef<HTMLInputElement>;
  @ViewChild('listInput') listInput: ElementRef<HTMLInputElement>;
  @ViewChild('countryInput') countryInput: ElementRef<HTMLInputElement>;

  loading = false;
  mapState$: Observable<MapState>;
  searchResults$: Observable<Poi[]>;

  filteredUsers$: Observable<User[]>;
  selectedUsers$: Observable<User[]>;
  user$: Observable<User>;

  filteredLists$: Observable<List[]>;
  selectedLists$: Observable<List[]>;

  selectedCountries$: Observable<Country[]>;
  filteredCountries$: Observable<Country[]>;

  selectedTypes$: Observable<Set<PoiType>>;

  searchControl: FormControl = new FormControl();
  peopleControl: FormControl = new FormControl();
  listControl: FormControl = new FormControl();
  countryControl: FormControl = new FormControl();

  minHeightControl: FormControl = new FormControl();
  maxHeightControl: FormControl = new FormControl();

  isoCodes = this.countries.isoCodes;

  expanded = false;

  PoiType = PoiType;

  ngOnInit(): void {
    this.searchResults$ = this.searchControl.valueChanges.pipe(
      debounceTime(500),
      tap(() => this.loading = true),
      switchMap(searchString => this.poiService.getWithQuery({q: searchString, lim: '10'}, {
        isOptimistic: false,
        mergeStrategy: MergeStrategy.OverwriteChanges,
      })),
      tap(() => this.loading = false)
    );
    // Set up user selection.
    this.user$ = this.authService.user;
    this.selectedUsers$ = this.mapService.state$.pipe(map(ms => ms.users));
    this.filteredUsers$ = combineLatest([this.peopleControl.valueChanges, this.userService.following$, this.selectedUsers$]).pipe(
      map(([userString, allUsers, selectedUsers]) => !userString || userString.id ? [] : allUsers.filter(u => {
          return !selectedUsers.includes(u) && u.name && u.name.toLocaleLowerCase().includes(userString.toLocaleLowerCase());
      }).sort((a, b) => a.name && a.name.localeCompare(b.name))
    ));
    // Set up list selection.
    this.selectedLists$ = this.mapService.state$.pipe(map(ms => ms.lists));
    this.filteredLists$ = combineLatest([this.listControl.valueChanges, this.listService.entities$, this.selectedLists$]).pipe(
      map(([listString, allLists, selectedLists]) => !listString || listString.id ? [] : allLists.filter(l => {
          return !selectedLists.includes(l) && l.name && l.name.includes(listString);
      }).sort(this.compareLists)
    ));
    // Set up type selection.
    this.selectedTypes$ = this.mapService.state$.pipe(map(ms => ms.types));

    // Set up country selection.
    this.selectedCountries$ = this.mapService.state$.pipe(map(ms => ms.countries));
    this.filteredCountries$ = combineLatest([this.countryControl.valueChanges, this.selectedCountries$]).pipe(
      map(([countryString, selectedCountries]) => !countryString || countryString.code ? [] : this.countries.isoCodes.filter(c => {
          return !selectedCountries.includes(c) && c.name && c.name.toLocaleLowerCase().includes(countryString.toLocaleLowerCase());
      }).sort((a, b) => a.name && a.name.localeCompare(b.name))
    ));

    this.mapService.state$.subscribe(ms => {
      this.minHeightControl.setValue(ms.minHeight || '');
      this.maxHeightControl.setValue(ms.maxHeight || '');
    });
    combineLatest([this.minHeightControl.valueChanges, this.maxHeightControl.valueChanges]).pipe(
      distinctUntilChanged(([oldMin, oldMax], [newMin, newMax]) => oldMin === newMin && oldMax === newMax),
      debounceTime(1000),
    ).subscribe(([minHeight, maxHeight]) => {
      this.mapService.setHeightRange(+minHeight, +maxHeight);
    });
  }

  private compareLists(a: List, b: List) {
    if (a.fav === b.fav) {
      return a.name && a.name.localeCompare(b.name);
    } else if (a.fav) {
      return -1;
    } else {
      return 0;
    }
  }

  display(poi: Poi) {
    return poi?.name;
  }

  getAvatarUrl(url?: string) {
    return this.photos.getAvatarUrl(url, AvatarSize.Small);
  }

  getFlagIcon(countryCode: string) {
    return this.countries.getSvgFlagIcon(countryCode);
  }

  selectPoi(poi: Poi) {
    this.mapService.setSelection([poi]);
  }

  close() {
    this.closeClick.next();
  }

  locate() {
    this.locateClick.next();
  }

  toggleSidenav() {
    this.appNavigation.toggleSidenav();
  }

  togglePanel() {
    this.expanded = !this.expanded;
  }

  userSelected(user: User) {
    this.mapService.addUser(user);
    this.peopleInput.nativeElement.value = '';
    this.peopleControl.setValue(null);
  }

  userRemoved(user: User) {
    this.mapService.removeUser(user);
  }

  listSelected(list: List) {
    this.mapService.addList(list);
    this.listInput.nativeElement.value = '';
    this.listControl.setValue(null);
  }

  listRemoved(list: List) {
    this.mapService.removeList(list);
  }

  countrySelected(country: Country) {
    this.mapService.addCountry(country);
    this.countryInput.nativeElement.value = '';
    this.countryControl.setValue(null);
  }

  countryRemoved(country: Country) {
    this.mapService.removeCountry(country);
  }

  typesUpdated(event: MatButtonToggleChange) {
    this.mapService.setPoiTypes(event.value);
  }
}

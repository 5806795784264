import {
  Component, OnInit, ContentChildren, QueryList, ChangeDetectorRef,
  AfterContentInit, Inject, forwardRef, ElementRef, NgZone, ContentChild,
  Input, ViewEncapsulation, ChangeDetectionStrategy, DoCheck, OnDestroy, HostBinding
} from '@angular/core';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { AnimationTriggerMetadata, trigger, state, style, transition, animate } from '@angular/animations';

const stDrawerAnimations: {
  readonly transformDrawer: AnimationTriggerMetadata;
} = {
  /** Animation that slides a drawer in and out. */
  transformDrawer: trigger('transform', [
    // We remove the `transform` here completely, rather than setting it to zero, because:
    // 1. Having a transform can cause elements with ripples or an animated
    //    transform to shift around in Chrome with an RTL layout (see #10023).
    // 2. 3d transforms causes text to appear blurry on IE and Edge.
    state('open', style({
      'transform': 'none',
      'visibility': 'visible',
    })),
    state('void', style({
      // Avoids the shadow showing up when closed in SSR.
      'box-shadow': 'none',
      'visibility': 'hidden',
    })),
    transition('void <=> open',
        animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)'))
  ])
};

@Component({
  selector: 'app-responsive-drawer',
  templateUrl: './responsive-drawer.component.html',
  styleUrls: ['./responsive-drawer.scss'],
  animations: [stDrawerAnimations.transformDrawer],
  host: {
    'class': 'st-responsive-drawer',
    // must prevent the browser from aligning text based on value
    '[attr.align]': 'null',
    '[class.st-responsive-drawer-bottom]': 'position === "bottom"',
    '[class.st-responsive-drawer-side]': 'position === "side"',
    '[class.st-responsive-drawer-opened]': 'opened',
    'tabIndex': '-1',
  },
})
export class ResponsiveDrawerComponent implements OnInit {
  @Input()
  get opened(): boolean { return this._opened; }
  set opened(value: boolean) { this.toggle(value); }
  private _opened = false;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
  ) { }

  @Input()
  get position(): string { return this._position; }
  set position(value: string) { this._position = value; }
  private _position = 'side';

  @HostBinding('@transform')
  animationState: 'open' | 'void' = 'void';

  get width(): number {
    return this.elementRef.nativeElement ? (this.elementRef.nativeElement.offsetWidth || 0) : 0;
  }

  get height(): number {
    return this.elementRef.nativeElement ? (this.elementRef.nativeElement.offsetHeight || 0) : 0;
  }

  toggle(isOpen: boolean = !this.opened) {
    this._opened = isOpen;
    if (isOpen) {
      this.animationState = 'open';
    } else {
      this.animationState = 'void';
    }
  }

  ngOnInit(): void {
  }



}

@Component({
  selector: 'app-responsive-drawer-content',
  template: '<ng-content></ng-content>',
  styleUrls: ['./responsive-drawer.scss'],
  host: {
    'class': 'st-responsive-drawer-content',
    '[style.margin-left.px]': 'container.contentMargins.left',
    '[style.margin-right.px]': 'container.contentMargins.right',
    '[style.height]': 'container.contentMargins.height',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ResponsiveDrawerContentComponent extends CdkScrollable implements AfterContentInit {
  constructor(
      private changeDetectorRef: ChangeDetectorRef,
      @Inject(forwardRef(() => ResponsiveDrawerContainerComponent)) public container: ResponsiveDrawerContainerComponent,
      elementRef: ElementRef<HTMLElement>,
      scrollDispatcher: ScrollDispatcher,
      ngZone: NgZone) {
    super(elementRef, scrollDispatcher, ngZone);
  }

  ngAfterContentInit() {
    this.container.contentMarginChanges.subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
  }
}

@Component({
  selector: 'app-responsive-drawer-container',
  templateUrl: './responsive-drawer-container.component.html',
  styleUrls: ['./responsive-drawer.scss'],
  host: {
    'class': 'st-responsive-drawer-container',
  },
})
export class ResponsiveDrawerContainerComponent implements OnInit, AfterContentInit, DoCheck, OnDestroy {
  @ContentChild(ResponsiveDrawerComponent) drawer: ResponsiveDrawerComponent;
  @ContentChild(ResponsiveDrawerContentComponent) content: ResponsiveDrawerContentComponent;

  /** Emits on every ngDoCheck. Used for debouncing reflows. */
  private readonly doCheckSubject = new Subject<void>();

  contentMargins: {left: number|null, right: number|null, height: SafeStyle|null} = {
    left: null, right: null, height: null};
  readonly contentMarginChanges = new Subject<{left: number|null, right: number|null, height: SafeStyle|null}>();

  /** Emits when the component is destroyed. */
  private readonly destroyed = new Subject<void>();

  constructor(
    private ngZone: NgZone,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this.updateContentMargins();

    this.doCheckSubject.pipe(
      debounceTime(10), // Arbitrary debounce time, less than a frame at 60fps
      takeUntil(this.destroyed)
    ).subscribe(() => this.updateContentMargins());
  }

  ngDoCheck() {
    // Run outside the NgZone, otherwise the debouncer will throw us into an infinite loop.
    this.ngZone.runOutsideAngular(() => this.doCheckSubject.next());
  }

  ngOnDestroy() {
    this.contentMarginChanges.complete();
    this.doCheckSubject.complete();
    this.destroyed.next();
    this.destroyed.complete();
  }

  /**
   * Recalculates and updates the inline styles for the content. Note that this should be used
   * sparingly, because it causes a reflow.
   */
  updateContentMargins() {
    // 1. For drawers in `side` mode, we shrink from the left by adding a left margin.
    // 2. For drawers in `bottom` mode, we shrink from bottom using the bottom property.

    let left = 0;
    let right = 0;
    let height = null;

    if (this.drawer.opened && this.drawer.position === 'side') {
      left = this.drawer.width;
    } else if (this.drawer.opened && this.drawer.position === 'bottom') {
      height = this.sanitizer.bypassSecurityTrustStyle(`calc(100% - ${this.drawer.height}px)`);
    }

    // If either `right` or `bottom` is zero, don't set a style to the element. This
    // allows users to specify a custom size via CSS class in SSR scenarios where the
    // measured widths will always be zero. Note that we reset to `null` here, rather
    // than below, in order to ensure that the types in the `if` below are consistent.
    left = left || null!;
    right = right || null!;
    height = height || null!;

    if (left !== this.contentMargins.left
      || right !== this.contentMargins.right
      || height !== this.contentMargins.height) {
      this.contentMargins = {left, right, height};
      // Pull back into the NgZone since in some cases we could be outside. We need to be careful
      // to do it only when something changed, otherwise we can end up hitting the zone too often.
      this.ngZone.run(() => this.contentMarginChanges.next(this.contentMargins));
    }
  }

}

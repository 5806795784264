<h1>Analysis</h1>
<p>
    <mat-form-field>
        <mat-select [value]="(selectedPerson$ | async)" (selectionChange)="personChanged($event)">
            <mat-option *ngFor="let person of (people$ | async)" [value]="person">
                {{person.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</p>
<mat-grid-list cols="2" rowHeight="fit" style="min-height: 600px; height: 100%">
    <mat-grid-tile>
        <mat-card class="chart-card">
            <mat-card-title>
                Summary of Favourite Lists
            </mat-card-title>
            <google-chart *ngIf="favProgress"
                type="BarChart" [options]="favProgressOptions"
                [data]="favProgress.data"
                [columns]="favProgress.columns"
                [dynamicResize]="true" style="width: 100%"></google-chart>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="chart-card">
            <mat-card-title>
                Bagged Peak Heights
            </mat-card-title>
            <google-chart *ngIf="poiHeights"
                type="ColumnChart" 
                [options]="{legend: 'none'}"
                [data]="poiHeights.data"
                [columns]="poiHeights.columns"
                [dynamicResize]="true" style="width: 100%"></google-chart>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile colspan="2">
        <mat-card class="chart-card">
            <mat-card-title>
                Bagging Over Time
            </mat-card-title>
            <google-chart *ngIf="baggingTime"
                type="ColumnChart" 
                [options]="{legend: 'none'}"
                [data]="baggingTime.data"
                [columns]="baggingTime.columns"
                [dynamicResize]="true" style="width: 100%"></google-chart>
        </mat-card>       
    </mat-grid-tile>
</mat-grid-list>

<ng-container *ngIf="(pois$ | async) as pois">
<div id="header" class="st-primary">
    <ng-container>
      <img *ngIf="pois && pois.length && pois[0].picture; else noPic" id="picture" class="photo" [src]="photos.getResizedUrl(pois[0].picture, 400)" />
    </ng-container>
    <ng-template #noPic>
        <mat-icon id="picture" class="icon">
            terrain
        </mat-icon>
    </ng-template>
  
    <div class="text">
      <div>
        <h1>
          <span class="name">
            Multi
          </span>
        </h1>
        <p>
          Multiselect
        </p>
      </div>
    </div>
    <ng-container *ngIf="(user$ | async)?.admin">
        <button *ngIf="pois.length" mat-fab color="primary" class="st-sideactionfab"
            (click)="merge(pois[0], pois.slice(1))">
            <mat-icon *ngIf="!merging; else newLogSpinner">merge_type</mat-icon>
            <ng-template #newLogSpinner>
              <mat-progress-spinner mode="indeterminate" color="accent" diameter="24"></mat-progress-spinner>
            </ng-template>
   
        </button>
    </ng-container>
</div>

<mat-nav-list>
    <mat-list-item class="poi-list-item" *ngFor="let poi of pois$ | async; let i = index">
        <a matLine [routerLink]="['/poi/', poi.id]" queryParamsHandling="merge">
            <h3 matLine>
                <span class="ranking">{{i+1}}.</span>
                <span class="name">
                  {{poi.name}}
                </span>
                <span class="height">{{poi.height}}</span>
            </h3>
            <p matLine>
                <mat-icon [svgIcon]="poi.country ? 'flags:' + countries.getSvgFlagIcon(poi.country.toLowerCase()) : ''"></mat-icon>
                {{poi.country}}&nbsp;
                <span *ngIf="poi.region"> - {{poi.region}}</span>
            </p>
        </a>
    </mat-list-item>
</mat-nav-list>
</ng-container>
import { Component, OnInit } from '@angular/core';
import { AppNavigationService } from 'src/app/app-navigation.service';

@Component({
  selector: 'app-fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.scss']
})
export class FullscreenComponent implements OnInit {

  constructor(
    private appNavigation: AppNavigationService
  ) { }

  ngOnInit(): void {
  }

  toggleSidenav() {
    this.appNavigation.toggleSidenav();
  }
}

/**
 * Create a map type which multiplexes tiles from other maptypes based on bounding boxes.
 * @param tileSize The tilesize - these must be the same for all map types.
 */

export class MultiTypeMultiplexer implements google.maps.MapType {
    minZoom = 0;
    maxZoom = 0;

    private types: [google.maps.Polygon, google.maps.MapType][] = [];

    constructor(
        public tileSize: google.maps.Size,
        public projection: google.maps.Projection,
        private baseMapType?: google.maps.MapType) {}

    addMapType(polygon: google.maps.Polygon, mapType: google.maps.MapType) {
        this.types.push([polygon, mapType]);
        this.minZoom = Math.min(this.minZoom, mapType.minZoom);
        this.maxZoom = Math.max(this.maxZoom, mapType.maxZoom);
    }

    addMapTypes(types: [google.maps.Polygon, google.maps.MapType][]) {
        for (const type of types) {
            this.addMapType(type[0], type[1]);
        }
    }

    getTile(tileCoord: google.maps.Point, zoom: number, ownerDocument) {
        let mapType = this.baseMapType;

        const pixelCoord = new google.maps.Point(
                tileCoord.x * this.tileSize.width,
                tileCoord.y * this.tileSize.height);

        const worldCoordNW = new google.maps.Point(
                pixelCoord.x / Math.pow(2, zoom),
                pixelCoord.y / Math.pow(2, zoom));

        const worldCoordSE = new google.maps.Point(
                (pixelCoord.x + this.tileSize.width) / Math.pow(2, zoom),
                (pixelCoord.y + this.tileSize.height) / Math.pow(2, zoom));

        const latLngNW = this.projection.fromPointToLatLng(worldCoordNW);
        const latLngSE = this.projection.fromPointToLatLng(worldCoordSE);

        for (const polyType of this.types) {
            const [poly, type] = polyType;
            if ((google.maps.geometry.poly.containsLocation(latLngNW, poly)
                    || google.maps.geometry.poly.containsLocation(latLngSE, poly))
                    && type.minZoom <= zoom && type.maxZoom >= zoom) {
                mapType = type;
                break;
            }
        }

        let tile;
        if (mapType) {
            tile = mapType.getTile(tileCoord, zoom, ownerDocument);
            tile._multiplexerMapType = mapType;
        } else {
            tile = ownerDocument.createElement('div');
            tile._multiplexerMapType = this;
        }
        return tile;
    }

    releaseTile(tile) {
        if (tile._multiplexerMapType !== this) {
            tile._multiplexerMapType.releaseTile(tile);
        } else {
            // delete div?
        }
    }
}

<button mat-fab class="st-mainfab" color="primary" (click)="followByEmail()" matTooltip="Invite a person via email" aria-label="Shows a dialog to enter an email address of someone to invite">
    <mat-icon>
        person_add
    </mat-icon>
</button>

<h1>Social</h1>
<ng-container *ngFor="let person of people$ | async" [ngTemplateOutlet]="profileCard" [ngTemplateOutletContext]="{person: person}" >
</ng-container>


<ng-template #profileCard let-person="person">
    <mat-card class="person" [class.pending]="isPendingFollow(person)">
        <mat-card-header>
            <img mat-card-avatar [src]="getAvatarUrl(person.photo_url)" />
            <mat-card-title>
                {{person.name}}
            </mat-card-title>
            <mat-card-subtitle>
                {{person.email}}
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <button mat-flat-button color="accent" *ngIf="!isPendingFollow(person) && !isFollowed(person) && isFollower(person)" (click)="followBack(person)">
                FOLLOW BACK
            </button>
            <button mat-flat-button color="accent" *ngIf="isPendingFollower(person)" (click)="acceptFollow(person)">
                ACCEPT FOLLOW REQUEST
            </button>            
        </mat-card-content>
        <mat-card-actions>

            <ng-container *ngIf="isFollowed(person)">
                <button mat-icon-button [routerLink]="['/ranking', person.id]" matTooltip="View this person's highest summits & points of interest">
                    <mat-icon>
                        done_all
                    </mat-icon>
                </button>
                <button mat-icon-button [routerLink]="['/profile/analysis', person.id]" matTooltip="View an analysis of this person's log entries">
                    <mat-icon>
                        show_chart
                    </mat-icon>
                </button>
                <button mat-icon-button matTooltip="Stop following this person">
                    <mat-icon>
                        delete
                    </mat-icon>
                </button>
            </ng-container>
            <button mat-icon-button *ngIf="isFollower(person) || isPendingFollower(person)" (click)="rejectFollow(person)">
                <mat-icon matTooltip="Stop this person following you">
                    not_interested
                </mat-icon>
            </button>
        </mat-card-actions>
    </mat-card>
</ng-template>

<ng-template #addUserDialog>
<h2 mat-dialog-title>Invite By Email</h2>
<mat-dialog-content>
<mat-form-field>
    <input matInput type="email" [formControl]="emailControl" />
    <mat-icon matSuffix>
        email
    </mat-icon>
</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="emailControl.value">Send Invitation</button>
</mat-dialog-actions>
</ng-template>
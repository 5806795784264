<mat-grid-list cols="5">
    <mat-grid-tile *ngFor="let entry of last20$ | async">
        <mat-grid-tile-header>
            {{entry.poi?.name}}
        </mat-grid-tile-header>
        <img [src]="entry.poi?.picture" style="width: 100%; height: 100%; object-fit: cover;" />
        <mat-grid-tile-footer>
            {{entry.logEntry.visit_time}}
        </mat-grid-tile-footer>
    </mat-grid-tile>
</mat-grid-list>
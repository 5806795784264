<ng-container *ngIf="(rankedList$ | async) as pois">
    <div id="header" class="st-primary" *ngIf="pois.length > 1 && pois[0] as poi">
    <a [routerLink]="['/poi/', poi.id]">
        <img *ngIf="poi.picture; else noPic" id="picture" class="photo" [src]="getResizedPhotoUrl(poi.picture, 400)" />
        <ng-template #noPic>
            <mat-icon id="picture" class="icon">
                terrain
            </mat-icon>
        </ng-template>
        <div class="text">
          <h1>
            <span class="ranking">
              1. 
            </span>
            <span class="name">
                {{poi.name}}
            </span>
            <span class="height">
                {{poi.height}}
            </span>
          </h1>
          <p>
              <mat-icon [svgIcon]="poi.country ? getFlagIcon(poi.country.toLowerCase()) : ''"></mat-icon>
              {{poi.country}}&nbsp;
              <span *ngIf="poi.region"> - {{poi.region}}</span>
          </p>
        </div>
    </a>
    </div>
</ng-container>
<mat-nav-list *ngIf="rankedList$ | async as rankedList">
    <mat-list-item class="poi-list-item" *ngFor="let poi of rankedList.slice(1); let i = index">
        <a matLine [routerLink]="['/poi/', poi.id]" queryParamsHandling="merge">
            <h3 matLine>
                <span class="ranking">{{i+2}}.</span> <span class="name">{{poi.name}}</span>
                <span class="height">{{poi.height}}</span>
            </h3>
            <p matLine>
                <mat-icon [svgIcon]="poi.country ? getFlagIcon(poi.country.toLowerCase()) : ''"></mat-icon>
                {{poi.country}}&nbsp;
                <span *ngIf="poi.region"> - {{poi.region}}</span>
            </p>
        </a>
    </mat-list-item>
</mat-nav-list>
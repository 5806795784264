<button mat-mini-fab cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="expanded = !expanded" class="map-button">
    <mat-icon>
        layers
    </mat-icon>
</button>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="expanded"
    cdkConnectedOverlayFlexibleDimensions="false"
    cdkConnectedOverlayHasBackdrop="true"
    cdkConnectedOverlayBackdropClass="transparent"
    (detach)="expanded = false"
    (backdropClick)="expanded = false">
    <div style="display: flex; flex-direction: row;">
        <div *ngIf="expanded" class="st-panel" style="position: absolute; right: 0px; bottom: 40px; border-radius: 10px; padding: 4px; z-index: 100">
            <h3>Map Type</h3>
            <mat-button-toggle-group
                [value]="selectedBaseMapType"
                (change)="selectBaseMap($event)">
                <mat-button-toggle
                    *ngFor="let baseMapType of baseMapTypes"
                    class="base-map-img"
                    [value]="baseMapType">
                <img [src]="baseMapType.imgUrl" />
                {{baseMapType.name}}
                </mat-button-toggle>
            </mat-button-toggle-group>
            <h3>Map Details</h3>
            <mat-button-toggle-group
                [value]="selectedOverlayTypes"
                multiple="true"
                (change)="selectOverlays($event)">

                <mat-button-toggle 
                        *ngFor="let overlayType of overlayTypes"
                        class="base-map-img"
                        [value]="overlayType">
                    <img [src]="overlayType.imgUrl" />
                    {{overlayType.name}}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
</ng-template>
/**
 * A service to look-up svg icons for flags and other
 * things, like bagging modes.
 */

import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import isoCodesArray from './isocodes.json';
import { Dictionary } from '@ngrx/entity';

export interface Country {
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  registeredFlags = {};

  isoCodes: Country[] = isoCodesArray.map(c => ({code: c.Code, name: c.Name}));
  isoCodesMap = new Map(this.isoCodes.map(c => [c.code, c] as [string, Country]));

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
  }

  public getSvgFlagIcon(countryCode: string) {
    countryCode = countryCode.toLowerCase();
    if (!this.registeredFlags[countryCode]) {
      this.matIconRegistry.addSvgIconInNamespace('flags', countryCode, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/flags/4x3/${countryCode}.svg`));
      this.matIconRegistry.addSvgIconInNamespace('flags-square', countryCode, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/flags/1x1/${countryCode}.svg`));
      this.registeredFlags[countryCode] = true;
    }
    return countryCode;
  }
}

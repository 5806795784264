import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { User } from '../api';
import { PhotoService, AvatarSize } from '../photo.service';

@Component({
  selector: 'app-app-navigation',
  templateUrl: './app-navigation.component.html',
  styleUrls: ['./app-navigation.component.scss']
})
export class AppNavigationComponent implements OnInit {
  user: User;

  constructor(private authService: AuthService, private photoService: PhotoService) {
    authService.user.subscribe( user => {
      this.user = user;
    });
  }

  ngOnInit(): void {
  }

  signIn() {
    this.authService.signIn();
  }

  signOut() {
    this.authService.signOut();
  }

  getAvatarUrl(url: string) {
    return this.photoService.getAvatarUrl(url, AvatarSize.Small);
  }
}

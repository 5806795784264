import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, combineLatest, of, ReplaySubject } from 'rxjs';
import { DefaultService as Api, Poi, PoiService, ListService, List, User, PoisWithSummary } from 'src/app/api';
import { map, switchMap, shareReplay, tap, distinctUntilChanged, filter, distinctUntilKeyChanged } from 'rxjs/operators';
import { CountryService } from 'src/app/country.service';
import { PhotoService } from 'src/app/photo.service';
import { AuthService } from 'src/app/auth.service';
import { PoiListEditDialogComponent } from '../poi-list-edit-dialog/poi-list-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';

interface ListSummary {
  list: List;
  isEditable: boolean;
  allIds: string[];
  baggedIds: string[];
  baggedCount: number;
  total: number;
}

interface DataTableArray {
  cols: any[];
  rows: any[];
}

@Component({
  selector: 'app-poi-list',
  templateUrl: './poi-list.component.html',
  styleUrls: ['./poi-list.component.scss']
})
export class PoiListComponent implements OnInit {

  rankedList$: Observable<Poi[]>;
  listSummary$: Observable<ListSummary>;
  private pois$: ReplaySubject<PoisWithSummary> = new ReplaySubject(1);
  user: User;
  baggingTime: any;

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private countries: CountryService,
    private route: ActivatedRoute,
    private photos: PhotoService,
    private api: Api,
    private poiService: PoiService,
    private listService: ListService) { }

  ngOnInit(): void {
    const listId$ = this.route.paramMap.pipe(
      map(paramMap => paramMap.get('listId')),
      distinctUntilChanged());
    // Refresh the list information when the id changes.
    const list$ = combineLatest([listId$, this.listService.entityMap$]).pipe(
      map(([listId, entityMap]) => entityMap[listId]),
      filter(list => !!list)
    );
    listId$.pipe(
      switchMap(listId => this.poiService.getWithQueryIncludeSummary({lists: [listId], lim: '500'})),
    ).subscribe(this.pois$);

    this.listSummary$ = combineLatest([list$, this.pois$, this.authService.user]).pipe(
      map(([list, apiSummary, user]) => ({
              list,
              isEditable: this.authService.canEdit(list, user),
              allIds: apiSummary.pois.map(poi => poi.id),
              // TODO: Fix baggedId propagation from API.
              baggedIds: apiSummary.pois.filter(poi => poi.bagged_count > 0).map(poi => poi.id),
              baggedCount: apiSummary.bagged,
              total: apiSummary.total,
            }))
    );
    // Cumulative bagging towards target.
    combineLatest([this.listSummary$, this.authService.user]).subscribe(([summary, user]) => {
      if (!user) {
        this.baggingTime = null;
        return;
      }
      this.api.getChart(
          'baggingtime',
          user.id,
          summary.list.id,
          'daily',
          /* firstVisitOnly */ true).subscribe((time: DataTableArray) => {
        const cols = time.cols;
        const rows = [];
        let cumulative = 0;
        for (const r of time.rows) {
          cumulative += r.c[1].v;
          rows.push({
            c: [r.c[0], {v: cumulative} , {v: summary.total}]
          });
        }
        cols.push('target');
        this.baggingTime = {
          columns: cols,
          data: rows
        };
      });
    });
    this.rankedList$ = combineLatest([this.listSummary$, this.poiService.entityMap$]).pipe(
      map(([summary, allPois]) => summary.allIds.map(id => allPois[id])/*.filter((poi: Poi) => !!poi)*/),
    );
    this.authService.user.subscribe(user => this.user = user);
  }

  getFlagIcon(countryCode: string) {
    return 'flags:' + this.countries.getSvgFlagIcon(countryCode);
  }

  getResizedPhotoUrl(url: string, height: number) {
    return this.photos.getResizedUrl(url, height);
  }

  toggleFav(list: List) {
    if (list.fav) {
      this.listService.removeFavList(this.user, list);
    } else {
      this.listService.addFavList(this.user, list);
    }
  }

  edit(list: List) {
    const dialogRef = this.dialog.open(PoiListEditDialogComponent, {
      data: { list, },
      width: '400px',
    });
    dialogRef.afterClosed().subscribe(updatedList => {
      if (updatedList) {
        this.listService.update(updatedList, { isOptimistic: true });
      }
    });
  }

  deleteFromList(list: List, poi: Poi) {
    // TODO: Make this update the list of IDs.
    this.poiService.removePoiFromList(poi, list).subscribe(() => {
      this.poiService.getWithQueryIncludeSummary({lists: [list.id]}).subscribe(this.pois$);
    });
  }
}

import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, MergeStrategy, QueryParams, EntityActionOptions } from '@ngrx/data';
import { DefaultService as Api, List, Poi } from './api';
import { Observable, ConnectableObservable } from 'rxjs';
import { switchMap, publishReplay, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PoiService extends EntityCollectionServiceBase<Poi> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private api: Api) {
    super('Poi', serviceElementsFactory);
  }

  addPoiToList(poi: Poi, list: List): Observable<Poi> {
    const r$ = (this.api.addPoiToList(list.id, poi.id).pipe(
      switchMap((r) => this.getByKey(poi.id, {mergeStrategy: MergeStrategy.OverwriteChanges})),
      publishReplay(1),
    ) as ConnectableObservable<Poi>);
    r$.connect();
    return r$;
  }

  removePoiFromList(poi: Poi, list: List): Observable<Poi> {
    const r$ = (this.api.removePoiFromList(list.id, poi.id).pipe(
      switchMap((r) => this.getByKey(poi.id, {mergeStrategy: MergeStrategy.OverwriteChanges})),
      publishReplay(1),
    ) as ConnectableObservable<Poi>);
    r$.connect();
    return r$;
  }

  getWithQueryIncludeSummary(queryParams: QueryParams, options?: EntityActionOptions) {
    // TODO: Allow overriding lim.
    const lim = 800;
    let query = queryParams.q;
    if (query?.length) {
      query = (query as string[]).join(' ');
    }
    let lists = queryParams.lists;
    if (lists?.length) {
      lists = (lists as string[]).join(',');
    }
    const r$ = this.api.listPoisWithSummary((query as string), (lists as string), lim).pipe(shareReplay(1));
    r$.subscribe(summary => this.upsertManyInCache(summary.pois));
    return r$;
  }
}

<!-- Linear progress while we load markers. -->
<mat-progress-bar
    *ngIf="loading"
    id="loading"
    mode="indeterminate">
</mat-progress-bar>
<app-search-overlay
    [closeAllowed]="routeActive" (closeClick)="closeSidenavContent()"
    [locateAllowed]="gpsState == GpsState.READY" [locateError]="gpsState == GpsState.ERROR" (locateClick)="goToLocation()"></app-search-overlay>
<app-responsive-drawer-container fullscreen>
    <app-responsive-drawer
        [position]="(app.isPortrait | async) ? 'bottom' : 'side'"
        [opened]="routeActive">
        <router-outlet #primaryOutlet (activate)="routeActivated()" (deactivate)="routeDeactivated()"></router-outlet>
    </app-responsive-drawer>>
    <app-responsive-drawer-content>
        <google-map
            height="100%" width="100%"
            (keydown.shift)="multiselect = true;"
            (keyup.shift)="multiselect = false;">
        </google-map>
        <app-user-control></app-user-control>
        <app-layer-control
            [baseMapTypes]="baseMapTypes"
            [selectedBaseMapType]="selectedBaseMapType"
            [overlayTypes]="overlayTypes"
            [selectedOverlayTypes]="selectedOverlayTypes"
            (baseMapChanged)="selectBaseMap($event)"
            (overlayTypesChanged)="selectOverlays($event)"></app-layer-control>
    </app-responsive-drawer-content>
</app-responsive-drawer-container>

<div *ngIf="poi$ | async as poi" class="mat-typography" style="min-height: 300px">
    <div id="header" class="st-primary">
        <img *ngIf="poi.picture; else noPic" id="picture" class="photo" [src]="getResizedPhotoUrl(poi.picture, 400)" />
        <ng-template #noPic>
            <mat-icon id="picture" class="icon">
                terrain
            </mat-icon>
        </ng-template>
        <div class="text">
          <h1>
              <ng-container *ngIf="poi.poi_type">
                <mat-icon [svgIcon]="'types:' + poi.poi_type"></mat-icon>&nbsp;
              </ng-container>
              {{poi.name}}&nbsp;
              <mat-icon *ngIf="(log$ | async).length > 0" class="check">
                  check_circle
              </mat-icon>
          </h1>
          <p>
              <mat-icon [svgIcon]="poi.country ? getFlagIcon(poi.country.toLowerCase()) : ''"></mat-icon>
              {{poi.country}}&nbsp;
              <span *ngIf="poi.region"> - {{poi.region}}</span>
          </p>
        </div>
    </div>
    <button mat-fab color="primary" class="st-sideactionfab" (click)="addLogEntry(poi.id)">
        <mat-icon *ngIf="!newLogEntry; else newLogSpinner">
            {{(log$ | async).length > 0 ? 'add' : 'check'}}
        </mat-icon>
        <ng-template #newLogSpinner>
          <mat-progress-spinner mode="indeterminate" color="accent" diameter="24"></mat-progress-spinner>
        </ng-template>
    </button>
    <div class="details-panel">
      <table class="details-panel">
          <tr>
            <th>
              <mat-icon>vertical_align_top</mat-icon>
            </th>
            <td *ngIf="poi.height">
              {{poi.height}}m
            </td>
            <td *ngIf="!poi.height">
              No height
              <!-- TODO: Wikipedia editing -->
              <!--<span ngIf="$ctrl.fullPoi.wikiId">
                <a [href]="'http://wikidata.org/wiki/' + poi.wikiId">(edit on Wikidata)</a>
              </span>-->
            </td>
          </tr>
          <tr *ngIf="(user | async)">
              <th><mat-icon>check_circle</mat-icon></th>
              <td>
                <span *ngFor="let baggedUser of baggedBy$ | async">
                <a [routerLink]="['/profile/', baggedUser.id]">
                  <img [src]="getAvatarUrl(baggedUser.photo_url)" class="st-avatar-small" [matTooltip]="baggedUser.name" />
                </a>
                </span>
                  
                  <div *ngIf="(baggedBy$ | async).length == 0">
                      No visits by friends.
                  </div>
              </td>
          </tr>
          <tr *ngIf="poi.list_ids">
            <th>
              <mat-icon>
                list
              </mat-icon>
            </th>
            <td>
              <mat-menu #listMenu="matMenu">
                <button *ngFor="let list of availableLists$ | async" mat-menu-item (click)="addPoiToList(poi, list)">
                  {{list.name}}
                </button>
              </mat-menu>
              <mat-chip-list>
                <button mat-icon-button [matMenuTriggerFor]="listMenu" *ngIf="user | async">
                  <mat-icon>
                    add_circle
                  </mat-icon>
                </button>
                <mat-chip *ngFor="let list of lists$ | async" [removable]="canEditPoisInList(list, user | async)" (removed)="removePoiFromList(poi, list)">
                  <mat-icon matChipAvatar *ngIf="list.fav">
                    favorite
                  </mat-icon>
                  <a [routerLink]="['/list', list.id]">
                    {{list.name}}
                  </a>
                  <mat-icon matChipRemove *ngIf="canEditPoisInList(list, user | async)">cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </td>
          </tr>
          <!--
          <tr ng-show="$ctrl.fullPoi.sources">
            <th>
              <md-icon>
                edit
              </md-icon>
            </th>
            <td>
              <md-chips class="nounderline" name="sources" ng-model="$ctrl.fullPoi.sources" readonly="true">
                <md-chip-template>
                  <div style="vertical-align: middle; font-size: small">
                  <img ng-src="/icons/{{$ctrl.getIconForSource($chip)}}" style="object-fit: cover; height: 16px">
                  <a ng-href="{{$ctrl.getLinkForSource($chip)}}">{{$chip.id}}</a>
                  </div>
                </md-chip-template>
              </md-chips>
            </td>
          </tr>-->
      </table>
      <!-- 
        Visit log for this POI.
      -->

      <mat-accordion displayMode="flat">
        <app-log-entry
          *ngFor="let logEntry of log$ | async"
          [logEntry]="logEntry"
          [expanded]="expandedLogIds.includes(logEntry.id)"
          (opened)="logEntryOpened(logEntry)"
          (closed)="logEntryClosed(logEntry)">
        </app-log-entry>
      </mat-accordion>
    </div>
</div>

<mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header>
    <mat-panel-description>
        <span class="date">{{logEntry.visit_time | amFromUtc | amDateFormat: 'Do MMM YYYY'}}</span>
        <div>
            <mat-icon *ngFor="let mode of logEntry.modes || ['hiking']" [svgIcon]="'modes:' + mode" matSuffix></mat-icon>
        </div>
    </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-form-field>
        <input matInput [matDatepicker]="datePicker" [value]="logEntry.visit_time" (dateChange)="dateChange($event.value)" />
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    </mat-form-field>

    <mat-button-toggle-group name="mode" aria-label="Mode" multiple (change)="modeChange($event)">
    <mat-button-toggle *ngFor="let mode of Mode | keyvalue"
        [checked]="(logEntry.modes || ['hiking']).includes(mode.value)"
        [value]="mode.value">
        <mat-icon [svgIcon]="'modes:' + mode.value" aria-label="mode"></mat-icon>
    </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-action-row>
    <button mat-icon-button (click)="deleteLogEntry()">
        <mat-icon>
        delete
        </mat-icon>
    </button>
    </mat-action-row>
</mat-expansion-panel>
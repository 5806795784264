<mat-nav-list>
    <a mat-list-item href="/api/admin/updatedb?cmd=dobih" target="_blank">
        Update DoBIH
    </a>
    <a mat-list-item href="/api/admin/updatedb?cmd=sota" target="_blank">
        Update SOTA
    </a>
    <a mat-list-item href="/api/admin/updatedb?cmd=wiki" target="_blank">
        Update Wiki
    </a>
    <a mat-list-item href="/api/admin/updatedb?cmd=add_pois" target="_blank">
        Add New Source POIs
    </a>
    <a mat-list-item href="/api/admin/updatedb?cmd=merge_pois" target="_blank">
        Generate POIs
    </a>
</mat-nav-list>
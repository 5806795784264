import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DefaultService as ApiService, UserService, User } from 'src/app/api';
import { AuthService } from 'src/app/auth.service';
import { combineLatest, Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';

interface DataTableArray {
  cols: any[];
  rows: any[];
}

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent implements OnInit {
  people$: Observable<User[]>;
  selectedPerson$: Observable<User>;

  constructor(
    private authService: AuthService,
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService) {
    }

  favProgress: any;
  poiHeights: any;
  baggingTime: any;
  favProgressOptions = {legend: {position: 'top'}, bars: 'horizontal', isStacked: 'percent'};

  ngOnInit(): void {
    this.people$ = combineLatest([this.authService.user, this.userService.following$]).pipe(
      map(([authUser, following]) => [authUser].concat(following)));

    this.selectedPerson$ = combineLatest([this.authService.userId, this.route.paramMap, this.userService.entityMap$]).pipe(
      map(([authUserId, paramMap, peopleMap]) => {
        const userId = paramMap.get('userId') || authUserId;
        return peopleMap[userId];
      }),
      filter(user => !!user)
    );
    this.selectedPerson$.subscribe(user => {
      this.api.getChart('progress', user.id).subscribe((progress) => {
        this.favProgress = this.generateProgressChart(progress);
      });
      this.api.getChart('poiheights', user.id).subscribe((heights: DataTableArray) => {
        this.poiHeights = {
          columns: heights.cols,
          data: heights.rows
        };
      });
      this.api.getChart('baggingtime', user.id).subscribe((time: DataTableArray) => {
        this.baggingTime = {
          columns: time.cols,
          data: time.rows
        };
      });
    });
  }

  // TODO: Apply real types.
  private generateProgressChart(listProgress: any) {
    const maxRound = Math.max(...listProgress.map(row => Math.max(...Object.keys(row.rounds).map(rs => +rs))));
    const rounds = Array(maxRound + 1);
    rounds[maxRound] = 'Remaining';
    for (let r = 1; r <= maxRound; r++) {
      rounds[maxRound - r] = ('Round ' + r);
    }
    const favRows = listProgress.filter(r => r.fav);
    let header: any[] = ['Name'];
    header = header.concat(rounds).concat([ { role: 'annotation' }]);
    const favData: any[][] = [header];
    for (const favRow of favRows) {
      const dataRow = new Array(maxRound + 3); // include Name and annotation fields
      dataRow[0] = favRow.name;
      dataRow[maxRound + 2] = favRow.complete + ' / ' + favRow.poi_count;
      for (let i = 1; i <= maxRound + 1; i++) {
        dataRow[i] = Number(favRow.rounds[(maxRound + 1) - i]) || 0;
      }
      favData.push(dataRow);
    }
    return {
      columns: favData[0],
      data: favData.slice(1),
    };
  }

  personChanged($event: MatSelectChange) {
    this.router.navigate(['/profile/analysis', $event.value.id], {
      queryParamsHandling: 'merge',
    });
  }
}

const layers = [];

layers['topo'] = {
	base: 'https://wmts20.geo.admin.ch',
	name: 'ch.swisstopo.pixelkarte-farbe',
	timestamp: 'current',
	format: 'jpeg'
};

//  - Hiking: ch.swisstopo.swisstlm3d-wanderwege (png)
layers['hiking'] = {
	base: 'https://wmts20.geo.admin.ch',
	name: 'ch.swisstopo.swisstlm3d-wanderwege',
	timestamp: 'current',
	format: 'png'
};

layers['cycling'] = {
	base: 'https://wmts20.geo.admin.ch',
	name: 'ch.astra.veloland',
	timestamp: 'current',
	format: 'png'
};

export function getSwissTopoLayer(type) {
    // Other interesting layers:
    //  - Mountain Biking: ch.astra.mountainbikeland
    //  - Slopes over 30 deg (avalanche): ch.swisstopo-karto.hangneigung (png)

	
	var layer = layers[type];
	
	// If this breaks, check the BASE_URL at https://wmts10.geo.admin.ch/EPSG/3857/1.0.0/WMTSCapabilities.xml
	//var cfg = 
	//cfg = [cfg].slice()[0];
	return new google.maps.ImageMapType({
        maxZoom: 17,
        minZoom: 10, // restrict zoom level so we don't burn tiles for top-level maps which are of little benefit
        name: layer.name,
        tileSize: new google.maps.Size(256, 256),
        //credit: 'swisstopo',
        getTileUrl: function(coord, zoom) {
                    return layer.base +  '/1.0.0/'+layer.name+'/default/'+layer.timestamp.toString()+'/3857/'+ zoom + "/" + coord.x + "/" + coord.y + "." + layer.format;
                },
    	opacity: 0.8
    });
}
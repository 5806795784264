import { Component, OnInit, Inject } from '@angular/core';
import { List } from 'src/app/api';
import { ListManagerComponent } from '../list-manager/list-manager.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-poi-list-edit-dialog',
  templateUrl: './poi-list-edit-dialog.component.html',
  styleUrls: ['./poi-list-edit-dialog.component.scss']
})
export class PoiListEditDialogComponent implements OnInit {
  list: Partial<List>;
  formGroup: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.list = data.list || {};
    this.formGroup = new FormGroup({
      name: new FormControl(this.list.name),
      description: new FormControl(this.list.description),
      link: new FormControl(this.list.link),
    });
    this.formGroup.valueChanges.subscribe((value: Partial<List>) => {
      this.list = {...{id: this.list.id}, ...value};
    });
  }

  ngOnInit(): void {
  }

}

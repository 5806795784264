import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ListService, List, User } from 'src/app/api';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { FormControl } from '@angular/forms';
import { PoiListEditDialogComponent } from '../poi-list-edit-dialog/poi-list-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';

function compareLists(a: List, b: List) {
  return (Number(b.fav) - Number(a.fav)) ||
    a.name.localeCompare(b.name);
}

@Component({
  selector: 'app-list-manager',
  templateUrl: './list-manager.component.html',
  styleUrls: ['./list-manager.component.scss']
})
export class ListManagerComponent implements OnInit {
  @ViewChild('deleteConfirmationDialog') deleteConfirmationDialog: TemplateRef<any>;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private authService: AuthService,
    private listService: ListService) {
  }

  filterControl = new FormControl('');
  userLists$: Observable<List[]>;
  filteredSystemLists$: Observable<List[]>;
  user: User;

  ngOnInit(): void {
    this.userLists$ = combineLatest([this.route.paramMap, this.listService.entities$]).pipe(
      map(([params, lists]) => lists.filter(
        list => list.user_id &&
        list.user_id === params.get('userId')).sort(compareLists))
    );
    this.filteredSystemLists$ = combineLatest([
      this.filterControl.valueChanges.pipe(startWith('')),
      this.listService.entities$]).pipe(
      map(([filter, lists]) => lists.filter(
        list => !list.user_id
        && (list.name || '').includes(filter)).sort(compareLists))
    );
    this.authService.user.subscribe(user => this.user = user);
  }

  toggleFav(list: List): void {
    if (list.fav) {
      this.listService.removeFavList(this.user, list);
    } else {
      this.listService.addFavList(this.user, list);
    }
  }

  canEdit(list: List): boolean {
    return this.authService.canEdit(list, this.user);
  }

  canDelete(list: List): boolean {
    return this.authService.canEdit(list, this.user) && !!list.user_id;
  }

  deleteList(list: List) {
    this.dialog.open(this.deleteConfirmationDialog, {
      data: { list, },
    }).afterClosed().subscribe(retVal => {
      if (retVal) {
        this.listService.delete(list);
      }
    });
  }

  editOrCreate(list?: List) {
    const dialogRef = this.dialog.open(PoiListEditDialogComponent, {
      data: { list, },
      width: '400px',
    });
    dialogRef.afterClosed().subscribe(updatedList => {
      console.log(updatedList);
      if (updatedList) {
        this.listService.upsert(updatedList);
      }
    });
  }
}

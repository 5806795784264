import { Component, OnInit } from '@angular/core';
import { CountryService } from 'src/app/country.service';
import { ActivatedRoute } from '@angular/router';
import { PhotoService } from 'src/app/photo.service';
import { DefaultService as DefaultApi, PoiService, Poi } from 'src/app/api';
import { Observable, combineLatest } from 'rxjs';
import { switchMap, map, shareReplay, tap } from 'rxjs/operators';
/*
  TODO: Show this on the map.
 */
@Component({
  selector: 'app-poi-ranking',
  templateUrl: './poi-ranking.component.html',
  styleUrls: ['./poi-ranking.component.scss']
})
export class PoiRankingComponent implements OnInit {
  rankedList$: Observable<Poi[]>;

  constructor(
    private countries: CountryService,
    private route: ActivatedRoute,
    private photos: PhotoService,
    private poiApi: DefaultApi,
    private poiService: PoiService) { }

  ngOnInit(): void {
    const poiIds$ = this.route.paramMap.pipe(
      switchMap(paramMap => this.poiApi.getRankedPois(paramMap.get('userId'))),
      tap(pois => this.poiService.upsertManyInCache(pois)),
      map(poiList => poiList.map(list => list.id)),
      shareReplay(1)
    );
    this.rankedList$ = combineLatest([poiIds$, this.poiService.entityMap$]).pipe(
      map(([ids, allPois]) => ids.map(id => allPois[id])/*.filter((poi: Poi) => !!poi)*/),
    );
  }

  getFlagIcon(countryCode: string) {
    return 'flags:' + this.countries.getSvgFlagIcon(countryCode);
  }

  getResizedPhotoUrl(url: string, height: number) {
    return this.photos.getResizedUrl(url, height);
  }
}

import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { LogEntry, LogService, Mode } from 'src/app/api';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Moment } from 'moment';
import { MatButtonToggleGroup, MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDatepickerInput } from '@angular/material/datepicker';
import { combineLatest, BehaviorSubject } from 'rxjs';
import { scan, debounce, debounceTime, skip } from 'rxjs/operators';

@Component({
  selector: 'app-log-entry',
  templateUrl: './log-entry.component.html',
  styleUrls: ['./log-entry.component.scss']
})
export class LogEntryComponent implements OnInit, AfterViewInit {
  @Input() logEntry: LogEntry;
  @Input() expanded: boolean;
  @Output() opened = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();

  @ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel;
  @ViewChild(MatButtonToggleGroup) toggleGroup: MatButtonToggleGroup;
  @ViewChild(MatDatepickerInput) datePickerInput: MatDatepickerInput<Moment>;

  Mode = Mode;

  private logEntryMutations$: BehaviorSubject<LogEntry | any>;

  constructor(
    private logService: LogService,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.expansionPanel.opened.subscribe(this.opened);
    this.expansionPanel.closed.subscribe(this.closed);
    this.logEntryMutations$ = new BehaviorSubject(this.logEntry);
    this.logEntryMutations$.pipe(
      scan((state, current) => ({...state, ...current})),
      skip(1),
      debounceTime(1000)
    ).subscribe(logEntry => {
      this.logService.update(logEntry, {
        isOptimistic: true,
      });
    });
  }

  // TODO: Fix the date/time handling.
  dateChange(value: Moment) {
    this.logEntryMutations$.next({
      visit_time: value.format('YYYY-MM-DDT00:00:00'),
    });
  }

  modeChange(event: MatButtonToggleChange) {
    this.logEntryMutations$.next({
      modes: event.value,
    });
  }

  deleteLogEntry() {
    this.logService.delete(this.logEntry);
  }
}

/**
 * Summit Tracker API
 * Provides POIs and user configuration for Summit Tracker
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InlineObject { 
    /**
     * The user\'s username from hill-bagging.co.uk.
     */
    dobih_username?: string;
    /**
     * The user\'s password from hill-bagging.co.uk.
     */
    dobih_password?: string;
    /**
     * The user\'s ID from walkhighlands.
     */
    walkhighlands_id?: number | string;
}


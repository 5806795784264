import { EntityMetadataMap } from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {
    User: {},
    Poi: {},
    List: {},
    LogEntry: {},
};

const pluralNames = { LogEntry: 'Log' };

export const entityConfig = {
    entityMetadata,
    pluralNames,
};

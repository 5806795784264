import { Component, OnInit, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { GoogleMapControlComponent } from '../google-map-control/google-map-control.component';
import { User } from 'src/app/api';

@Component({
  selector: 'app-user-control',
  templateUrl: './user-control.component.html',
  styleUrls: ['./user-control.component.scss']
})
export class UserControlComponent extends GoogleMapControlComponent implements OnInit {

  constructor(private authService: AuthService, el: ElementRef) {
    super(el);
    authService.user.subscribe( user => {
      this.user = user;
    });
  }

  user: User;
  ngOnInit(): void {
  }

  signIn() {
    this.authService.signIn();
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PoiService, Poi, User, UserService, List, ListService, LogService, LogEntry, compareLogEntries } from 'src/app/api';
import { Observable, combineLatest } from 'rxjs';
import { map, filter, delay, distinctUntilKeyChanged } from 'rxjs/operators';
import { PhotoService, AvatarSize } from 'src/app/photo.service';
import { AuthService } from 'src/app/auth.service';
import { CountryService } from 'src/app/country.service';
import { MergeStrategy } from '@ngrx/data';
import * as moment from 'moment';
import { MapService } from '../map.service';

@Component({
  selector: 'app-poidetails',
  templateUrl: './poidetails.component.html',
  styleUrls: ['./poidetails.component.scss']
})
export class PoiDetailsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private countries: CountryService,
    private listService: ListService,
    private logService: LogService,
    private mapService: MapService,
    private poiService: PoiService,
    private userService: UserService,
    private photos: PhotoService) { }

  poi$?: Observable<Poi>;
  user: Observable<User>;
  baggedBy$?: Observable<User[]>;
  lists$?: Observable<List[]>;
  availableLists$: Observable<List[]>;
  log$?: Observable<LogEntry[]>;
  newLogEntry?: Observable<LogEntry>;
  expandedLogIds: number[] = [];

  ngOnInit(): void {
    this.user = this.authService.user;
    const poiId$: Observable<string> = this.route.params.pipe(filter(params => params.poiId), map(params => params.poiId));
    combineLatest([poiId$, this.authService.user]).subscribe(([poiId, user]) => {
      this.poiService.getByKey(poiId, {isOptimistic: false, skip: false, mergeStrategy: MergeStrategy.OverwriteChanges});
      if (user) {
        this.logService.getWithQuery({poi_id: poiId/*, user_id: user.id*/});
      } else {
        this.logService.clearCache();
      }
    });
    this.poi$ = combineLatest([poiId$, this.poiService.entityMap$]).pipe(
      filter(([poiId, entityMap]) => !!entityMap[poiId]),
      map(([poiId, entityMap]) => entityMap[poiId])
    );
    // Update the selection to show a highlight on the map, and bring the POI into view.
    // Make sure we've got a version of the POI with lat/lon.
    this.poi$.pipe(
      filter(poi => poi.lat !== undefined && poi.lon !== undefined),
      distinctUntilKeyChanged('id')
    ).subscribe(poi => this.mapService.setSelection([poi]));

    this.baggedBy$ = combineLatest([poiId$, this.logService.entities$, this.userService.following$]).pipe(
      map(([poiId, log, following]) => {
        const baggedByIds = new Set(log.filter(l => l.poi_id === poiId).map(l => l.user_id));
        return following.filter(f => baggedByIds.has(f.id));
      })
    );
    this.lists$ = combineLatest([this.poi$, this.listService.entities$, this.poiService.collection$]).pipe(
      map(([poi, lists]) => lists.filter(list => poi.list_ids?.includes(list.id + '')))
    );
    this.availableLists$ = combineLatest([this.poi$, this.listService.entities$, this.authService.user]).pipe(
      map(([poi, lists, user]) => lists.filter(list => (
          !(poi.list_ids || []).includes(list.id + '') &&
          this.canEditPoisInList(list, user)
      ))
    ));
    this.log$ = combineLatest([this.poi$, this.logService.entities$, this.authService.user]).pipe(
      map(([poi, log, user]) => log
        .filter(logEntry => logEntry.user_id === user.id && logEntry.poi_id === poi.id)
        .sort(compareLogEntries))
    );
  }

  getResizedPhotoUrl(url: string, width?: number, height?: number) {
    return this.photos.getResizedUrl(url, width, height);
  }

  getAvatarUrl(url?: string) {
    return this.photos.getAvatarUrl(url, AvatarSize.Small);
  }

  getFlagIcon(cc: string) {
    return 'flags:' + this.countries.getSvgFlagIcon(cc);
  }

  addLogEntry(poiId: string) {
    this.newLogEntry = this.logService.add({
      id: null,
      poi_id: poiId,
      visit_time: moment().format('YYYY-MM-DDT00:00:00'),
    }).pipe(delay(0));
    this.newLogEntry.subscribe(logEntry => {
      this.expandedLogIds.push(logEntry.id);
      this.newLogEntry = null;
    });
  }

  logEntryOpened(logEntry: LogEntry) {
    this.expandedLogIds.push(logEntry.id);
  }

  logEntryClosed(logEntry: LogEntry) {
    this.expandedLogIds = this.expandedLogIds.filter(id => id !== logEntry.id);
  }

  addPoiToList(poi: Poi, list: List) {
    this.poiService.addPoiToList(poi, list);
  }

  removePoiFromList(poi: Poi, list: List) {
    this.poiService.removePoiFromList(poi, list);
  }

  canEditPoisInList(list: List, user: User) {
    return !list.automatic && this.authService.canEdit(list, user);
  }
}

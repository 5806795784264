import { Component, OnInit } from '@angular/core';
import { DefaultService as Api, PoiService, LogService, compareLogEntries, LogEntry, Poi } from 'src/app/api';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
import { Observable, combineLatest } from 'rxjs';
import { PhotoService } from 'src/app/photo.service';

interface LogEntryWithPoi {
  logEntry: LogEntry;
  poi: Poi;
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  last20$: Observable<LogEntryWithPoi[]>;

  constructor(
    private logService: LogService,
    private api: Api,
    private authService: AuthService,
    private poiService: PoiService,
    public photos: PhotoService) {
      this.authService.user.pipe(
        switchMap(user => this.api.listLogEntries(null, user.id, null, 20))
      ).subscribe(entries => {
        this.logService.upsertManyInCache(entries);
        for (const entry of entries) {
          this.poiService.getByKey(entry.poi_id);
        }
      });

      this.last20$ = combineLatest([this.authService.user, this.logService.entities$, this.poiService.entityMap$]).pipe(
        map(([user, entries, poiMap]) => {
          const entriesWithPois = entries.filter(e => e.user_id === user.id)
                                         .sort(compareLogEntries).slice(0, 19)
                                         .map(e => ({logEntry: e, poi: poiMap[e.poi_id]}));
          return entriesWithPois;
        })
      );
  }

  log$ = this.logService.entities$;

  ngOnInit(): void {
    // TODO: This end-point is not implemented yet.
  }

}

import { Injectable } from '@angular/core';
import { ReplaySubject, BehaviorSubject, combineLatest } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { map, distinctUntilChanged, shareReplay, filter, tap } from 'rxjs/operators';

export interface BaseMapType {
  id: string;
  name: string;
  description: string;
  imgUrl: string;
}

export interface OverlayType extends BaseMapType {
}

@Injectable({
  providedIn: 'root'
})
export class MapTypeManagerService {
  constructor(
    private cookies: CookieService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    const baseMapTypeId$ = this.route.queryParamMap.pipe(
      map(params => params.has('bm') ? params.get('bm') : this.cookies.get('bm')),
      distinctUntilChanged()
    );

    const overlayTypeIds$ = this.route.queryParamMap.pipe(
      map(params => (params.has('ol') ? params.get('ol') : this.cookies.get('ol')).split(',')),
      distinctUntilChanged()
    );

    combineLatest([this.availableBaseMapTypes$, baseMapTypeId$]).pipe(
      map(([baseMapTypes, baseMapTypeId]) => {
        for (const baseMapType of baseMapTypes) {
          if (baseMapType.id === baseMapTypeId) {
            return baseMapType;
          }
        }
        return null;
      }),
      filter(mt => !!mt),
      shareReplay(1)
    ).subscribe(this.selectedBaseMapType$);

    combineLatest([this.availableOverlayTypes$, overlayTypeIds$]).pipe(
      map(([overlayTypes, overlayTypeIds]) => {
        const selectedOverlayTypes = [];
        for (const overlayType of overlayTypes) {
          if (overlayTypeIds.includes(overlayType.id)) {
            selectedOverlayTypes.push(overlayType);
          }
        }
        return selectedOverlayTypes;
      }),
      shareReplay(1)
    ).subscribe(this.selectedOverlayTypes$);
  }

  private selectedBaseMapType$ = new ReplaySubject<BaseMapType>();
  private selectedOverlayTypes$ = new ReplaySubject<OverlayType[]>();
  private availableBaseMapTypes$ = new ReplaySubject<BaseMapType[]>();
  private availableOverlayTypes$ = new ReplaySubject<OverlayType[]>();

  baseMapType$ = this.selectedBaseMapType$.asObservable();
  overlayTypes$ = this.selectedOverlayTypes$.asObservable();

  selectBaseMap(baseMap: BaseMapType | string) {
    const bm = (baseMap as BaseMapType).id || (baseMap as string) || null;
    this.cookies.set('bm', bm || '', null, '/');
    this.router.navigate(
      [],
      {
          relativeTo: this.route,
          queryParams: { bm },
          queryParamsHandling: 'merge',
          replaceUrl: false,
      });
  }

  selectOverlays(overlays: (OverlayType | string)[]) {
    const ol = overlays.map(overlay => (overlay as OverlayType).id || (overlay as string)).join(',') || null;
    this.cookies.set('ol', ol || '', null, '/');
    this.router.navigate(
      [],
      {
          relativeTo: this.route,
          queryParams: { ol },
          queryParamsHandling: 'merge',
          replaceUrl: false,
      });
  }

  setBaseMapTypes(baseMapTypes: BaseMapType[]) {
    this.availableBaseMapTypes$.next(baseMapTypes);
  }

  setOverlayTypes(overlayTypes: OverlayType[]) {
    this.availableOverlayTypes$.next(overlayTypes);
  }
}

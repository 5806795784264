import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MapComponent } from './map/map.component';
import { PoiDetailsComponent } from './poidetails/poidetails.component';
import { ListManagerComponent } from './list-manager/list-manager.component';
import { PoiListComponent } from './poi-list/poi-list.component';
import { PoiRankingComponent } from './poi-ranking/poi-ranking.component';
import { MultiplePoisComponent } from './multiple-pois/multiple-pois.component';

const routes: Routes = [
    { path: '',
      component: MapComponent,
      children: [
        {
          path: 'poi/:poiId',
          component: PoiDetailsComponent,
        },
        {
          path: 'pois/:poiIds',
          component: MultiplePoisComponent,
        },
        {
          path: 'lists/:userId',
          component: ListManagerComponent,
        },
        {
          path: 'list/:listId',
          component: PoiListComponent,
        },
        {
          path: 'ranking/:userId',
          component: PoiRankingComponent,
        },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MapRoutingModule { }

import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, MergeStrategy } from '@ngrx/data';
import { DefaultService as Api, List, User } from './api';
import { switchMap, publishReplay } from 'rxjs/operators';
import { ConnectableObservable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListService extends EntityCollectionServiceBase<List> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private api: Api) {
    super('List', serviceElementsFactory);
  }

  addFavList(user: User, list: List) {
    const r$ = (this.api.addFavList(user.id, list.id).pipe(
      switchMap((r) => this.getByKey(list.id, {mergeStrategy: MergeStrategy.OverwriteChanges})),
      publishReplay(1),
    ) as ConnectableObservable<List>);
    r$.connect();
    return r$;
  }

  removeFavList(user: User, list: List) {
    const r$ = (this.api.removeFavList(user.id, list.id).pipe(
      switchMap((r) => this.getByKey(list.id, {mergeStrategy: MergeStrategy.OverwriteChanges})),
      publishReplay(1),
    ) as ConnectableObservable<List>);
    r$.connect();
    return r$;
  }
}

import { Injectable } from '@angular/core';

export enum AvatarSize {
  Small = 32,
  Medium = 48,
  Large = 64,
}

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  constructor() { }

  private defaultAvatarUrl = 'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg';
  getResizedUrl(imgUrl: string, width?: number, height?: number): string {
    const wikimediaPrefix = 'http://commons.wikimedia.org/wiki/Special:FilePath/';
    const googlePhotoImg = /^(http[s]:\/\/[^.]+.googleusercontent.com\/.*\/)(photo\.jpg)$/;
    const googleImg = /^(http[s]:\/\/[^.]+.googleusercontent.com\/.*\/)([^\/]+)$/;

    let img = imgUrl;
    if (img) {
      // Resize Wikipedia photos.
      if (img.startsWith(wikimediaPrefix)) {
        img = `https://${img.substring(7)}?`;
        if (width) {
          img += `width=${width}&`;
        }
        if (height) {
          img += `height=${height}`;
        }
        return img;
      }
      let m = img.match(googlePhotoImg);
      if (m) {
        // Resize Google usercontent photo URLs.
        const resize = [];
        if (width) {
          resize.push('w' + width);
        }
        if (height) {
          resize.push('h' + height);
        }
        img = m[1] + resize.join('-') + '/' + m[2];
      }
      m = img.match(googleImg);
      if (m) {
        // Resize Google usercontent 'new' URLs.
        const resize = [];
        if (width) {
          resize.push('w' + width);
        }
        if (height) {
          resize.push('h' + height);
        }
        img = `${m[1]}${m[2]}=${resize.join('-')}`;
      }
    }
    return img;
  }

  getAvatarUrl(imgUrl: string | undefined, size: AvatarSize): string {
    return this.getResizedUrl(imgUrl || this.defaultAvatarUrl, size, size);
  }
}

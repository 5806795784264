/**
 * Summit Tracker API
 * Provides POIs and user configuration for Summit Tracker
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface List { 
    /**
     * A unique ID for this list.
     */
    id?: string | null;
    /**
     * The display name for this list of POIs.
     */
    name?: string;
    /**
     * The ID of the user who owns this list. Empty if a \'system\' list.
     */
    user_id?: string | null;
    /**
     * True if the user has marked this list as a favourite.
     */
    fav?: boolean;
    /**
     * True if this list is populated automatically and POIs cannot be changed.
     */
    automatic?: boolean;
    /**
     * A hyperlink pointing to a detailed description of this list.
     */
    link?: string | null;
    /**
     * A short description of this list.
     */
    description?: string | null;
}


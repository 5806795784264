<mat-nav-list>
    <mat-list-item *ngIf="user" [routerLink]="['/profile']" queryParamsHandling="merge">
      <img matListAvatar [src]="getAvatarUrl(user.photo_url)" />
      <h3 matLine>{{user.name}}</h3>
      <p matLine>{{user.email}}</p>
    </mat-list-item>
    <mat-list-item [routerLink]="['/']" queryParamsHandling="merge">
      <mat-icon matListIcon>map</mat-icon>
      <p matLine>Map</p>
    </mat-list-item>
    <mat-list-item *ngIf="user" [routerLink]="['/lists/', user?.id]" queryParamsHandling="merge">
      <mat-icon matListIcon>list</mat-icon>
      <p matLine>Lists</p>
    </mat-list-item>
    <ng-container *ngIf="user">
      <mat-list-item [routerLink]="['/ranking/', user.id]" queryParamsHandling="merge">
        <mat-icon matListIcon>done_all</mat-icon>
        <p matLine>Completed</p>
      </mat-list-item>
      <mat-list-item [routerLink]="['/profile/analysis']" queryParamsHandling="merge">
        <mat-icon matListIcon>show_chart</mat-icon>
        <p matLine>Statistics</p>
      </mat-list-item>
      <mat-list-item [routerLink]="['/profile/social']" queryParamsHandling="merge">
        <mat-icon matListIcon>people</mat-icon>
        <p matLine>Social</p>
        <span matLine>Manage friends and followers.</span>
      </mat-list-item>
      <mat-list-item [routerLink]="['/profile/connections']" queryParamsHandling="merge">
        <mat-icon matListIcon>apps</mat-icon>
        <p matLine>Connections</p>
        <span matLine>Manage connections with other apps.</span>
      </mat-list-item>
      <ng-container *ngIf="user.admin">
        <mat-divider></mat-divider>
        <h3 matSubheader>Admin</h3>
        <mat-list-item [routerLink]="['/profile/admin']" queryParamsHandling="merge">
          <mat-icon matListIcon>build</mat-icon>
          <p matLine>Admin Tasks</p>
        </mat-list-item>
      </ng-container>
    </ng-container>
    <mat-list-item *ngIf="!user" (click)="signIn()">
      <mat-icon matListIcon>person</mat-icon>
      <p matLine>Sign In</p>
    </mat-list-item>
    <mat-list-item *ngIf="user" (click)="signOut()">
      <mat-icon matListIcon>person</mat-icon>
      <p matLine>Sign Out</p>
    </mat-list-item>
</mat-nav-list>

<p id="iconAck">
  Icons made by <a href="http://www.freepik.com">Freepik</a> from 
  <a href="http://www.flaticon.com">www.flaticon.com</a>.
</p>

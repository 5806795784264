import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MapRoutingModule } from './map-routing.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { GoogleChartsModule } from 'angular-google-charts';

import { MapComponent } from './map/map.component';
import { PoiDetailsComponent } from './poidetails/poidetails.component';
import { UserControlComponent } from './controls/user-control/user-control.component';
import { LayerControlComponent } from './controls/layer-control/layer-control.component';
import { SearchOverlayComponent } from './search-overlay/search-overlay.component';
import { GoogleMapControlComponent } from './controls/google-map-control/google-map-control.component';
import { CookieService } from 'ngx-cookie-service';
import { MomentModule } from 'ngx-moment';
import { LogEntryComponent } from './log-entry/log-entry.component';
import { ListManagerComponent } from './list-manager/list-manager.component';
import { PoiListComponent } from './poi-list/poi-list.component';
import { PoiRankingComponent } from './poi-ranking/poi-ranking.component';
import { PoiListEditDialogComponent } from './poi-list-edit-dialog/poi-list-edit-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ResponsiveDrawerComponent, ResponsiveDrawerContainerComponent, ResponsiveDrawerContentComponent } from './responsive-drawer/responsive-drawer.component';
import { MultiplePoisComponent } from './multiple-pois/multiple-pois.component';

@NgModule({
  declarations: [
    MapComponent,
    PoiDetailsComponent,
    UserControlComponent,
    LayerControlComponent,
    SearchOverlayComponent,
    GoogleMapControlComponent,
    LogEntryComponent,
    ListManagerComponent,
    PoiListComponent,
    PoiRankingComponent,
    PoiListEditDialogComponent,
    // Responsive layout.
    ResponsiveDrawerComponent,
    ResponsiveDrawerContainerComponent,
    ResponsiveDrawerContentComponent,
    MultiplePoisComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    GoogleChartsModule,
    LayoutModule,
    MapRoutingModule,
    MatAutocompleteModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatTooltipModule,
    MomentModule,
    OverlayModule,
    ReactiveFormsModule,
    GoogleMapsModule,
  ],
  providers: [
    CookieService,
  ],
  entryComponents: [
    PoiListEditDialogComponent,
  ]
})
export class MapModule { }

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';

import { DefaultService as ApiService } from 'src/app/api';
import { AuthService } from 'src/app/auth.service';
import { switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss']
})
export class ConnectionsComponent implements OnInit {

  constructor(private api: ApiService, private authService: AuthService, private snackBar: MatSnackBar) { }

  dobihGroup = new FormGroup({
    dobih_username: new FormControl(''),
    dobih_password: new FormControl(''),
  });
  walkhighlandsGroup = new FormGroup({
    walkhighlands_id: new FormControl(''),
  });
  dobih = false;
  walkhighlands = false;
  syncing = false;
  private userId: string;

  ngOnInit(): void {
    this.authService.userId.pipe(
      tap(userId => this.userId = userId),
      switchMap(userId => this.api.getUserConnections(userId))).subscribe((connections) => {
        this.dobihGroup.setValue({
          dobih_username: connections.dobih_username,
          dobih_password: ''
        });
        this.dobih = !!connections.dobih_username;
        this.walkhighlandsGroup.setValue({
          walkhighlands_id: connections.walkhighlands_id
        });
        this.walkhighlands = !!connections.walkhighlands_id;
      });
  }

  save(value) {
    this.api.putUserConnections(this.userId, value).subscribe(
      (success) => {},
      (error) => this.snackBar.open((error as HttpErrorResponse).statusText, 'DISMISS', { duration: 3000 })
    );
  }

  sync() {
    this.syncing = true;
    this.api.syncConnections(this.userId).subscribe(
      (success) => {
        this.syncing = false;
        if (success.added_count > 0) {
          this.snackBar.open(`Sync successful: added ${success.added_count} entries`, 'OK', { duration: 3000 });
        } else {
          this.snackBar.open(`Sync successful: no changes.`, 'OK', { duration: 3000 });
        }
      },
      (error) => {
        this.syncing = false;
        this.snackBar.open((error as HttpErrorResponse).statusText, 'DISMISS', { duration: 3000 });
      }
    );
  }
}

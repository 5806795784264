/**
 * Summit Tracker API
 * Provides POIs and user configuration for Summit Tracker
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface User { 
    /**
     * The unique id for the user.
     */
    id: string;
    /**
     * The display name of the user.
     */
    name?: string;
    /**
     * The email address of the user.
     */
    email?: string;
    /**
     * A URL with a display photo for the user.
     */
    photo_url?: string;
    /**
     * True if the user is a system admin.
     */
    admin?: boolean;
    /**
     * approved or pending.
     */
    follower?: string;
    /**
     * approved or pending.
     */
    following?: string;
}


import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { LogEntry } from '.';
import * as moment from 'moment';

export function compareLogEntries(a: LogEntry, b: LogEntry) {
  return moment(b.visit_time).valueOf() - moment(a.visit_time).valueOf();
}

@Injectable({
  providedIn: 'root'
})
export class LogService extends EntityCollectionServiceBase<LogEntry> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('LogEntry', serviceElementsFactory);
  }
}

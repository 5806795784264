import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapModule } from './map/map.module';
import { ProfileModule } from './profile/profile.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ApiModule, BASE_PATH } from './api';

import { environment } from '../environments/environment';
import { JwtInterceptor } from './jwt.interceptor';
import { AppNavigationComponent } from './app-navigation/app-navigation.component';
import { entityConfig } from './entity-metadata';
import { EntityDataModule } from '@ngrx/data';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MomentModule } from 'ngx-moment';

@NgModule({
  declarations: [
    AppComponent,
    AppNavigationComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase, 'angular-auth-firebase'),
    AngularFireAuthModule,
    BrowserModule,
    AppRoutingModule,
    MapModule,
    ProfileModule,
    BrowserAnimationsModule,
    ApiModule,
    HttpClientModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot(entityConfig),
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatMomentDateModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MomentModule,
  ],
  providers: [
    { provide: BASE_PATH, useValue: '' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'en-GB'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
